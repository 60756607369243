<template>
    <auth-layout>
        <container fluid>
            <row>
                <column>
                    <div v-if="!!status" style="position: relative; top: 100px">
                        <alert
                            v-if="status === 'passwords.sent'"
                            color="success"
                            v-html="trans('auth.reset_password.expired.sent')"
                        />
                        <alert
                            v-if="status === 'passwords.throttled'"
                            color="danger"
                            v-html="
                                trans('auth.reset_password.expired.throttled')
                            "
                        />
                    </div>
                </column>
            </row>
        </container>
        <container
            class="align-items-center min-vh-100"
            style="overflow: hidden"
        >
            <row class="min-vh-100 justify-content-center align-items-center">
                <column md="6">
                    <CCard class="p-4 m-0">
                        <CCardBody>
                            <h1>
                                {{
                                    trans('auth.reset_password.expired.header')
                                }}
                            </h1>
                            <p class="text-muted">
                                {{
                                    trans(
                                        'auth.reset_password.expired.description',
                                    )
                                }}
                            </p>
                            <div class="d-flex justify-content-end">
                                <jet-button
                                    :disabled="status === 'passwords.sent'"
                                    :is-processing="isProcessing"
                                    type="submit"
                                    @click.stop="resendLink"
                                    >{{
                                        trans(
                                            'auth.reset_password.expired.action.title',
                                        )
                                    }}
                                </jet-button>
                            </div>
                        </CCardBody>
                    </CCard>
                </column>
            </row>
        </container>
    </auth-layout>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';
import AuthLayout from '@/Layouts/AuthLayout.vue';
import FlashMessage from '@/Components/FlashMessage.vue';
import Container from '@/Components/Container.vue';

import Alert from '@/Components/Alert.vue';
import { $http } from '@/bootstrap.js';

export default {
    components: {
        Alert,

        Container,
        FlashMessage,
        JetButton,
        AuthLayout,
    },
    data() {
        return {
            isProcessing: false,
            status: null,
        };
    },
    methods: {
        resendLink() {
            this.isProcessing = true;

            $http
                .get(
                    this.route('password.resend-link', {
                        email: this.$page.props.email,
                    }),
                )
                .then((resp) => {
                    this.status = resp.data.status;
                })
                .finally(() => {
                    this.isProcessing = false;
                });
        },
    },
};
</script>
