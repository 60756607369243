<template>
    <div class="overflow-hidden table-rounded">
        <div
            class="table-responsive table-sticky-header"
            style="max-height: 60vw; overflow: auto"
        >
            <table
                :class="{ 'table-empty': !items.length }"
                class="table table-rounded border table-no-outline table-striped table-hover mb-0"
            >
                <thead>
                    <tr style="border-top: none">
                        <th style="vertical-align: middle; overflow: hidden">
                            <div class="text-truncate">
                                {{
                                    trans(
                                        'workorder.overview.records.cell.code',
                                    )
                                }}
                            </div>
                        </th>
                        <th style="vertical-align: middle; overflow: hidden">
                            <div class="text-truncate">
                                {{
                                    trans(
                                        'workorder.overview.records.cell.title',
                                    )
                                }}
                            </div>
                        </th>
                        <th style="vertical-align: middle; overflow: hidden">
                            <div class="text-truncate">
                                {{
                                    trans(
                                        'workorder.overview.records.cell.assignee',
                                    )
                                }}
                            </div>
                        </th>
                        <th style="vertical-align: middle; overflow: hidden">
                            <div class="text-truncate">
                                {{
                                    trans(
                                        'workorder.overview.records.cell.processing_time',
                                    )
                                }}
                            </div>
                        </th>
                        <th style="vertical-align: middle; overflow: hidden">
                            <div>
                                {{
                                    trans(
                                        'workorder.overview.records.cell.result',
                                    )
                                }}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody v-if="items.length">
                    <tr
                        v-for="item in items"
                        :key="item.id"
                        style="cursor: pointer"
                        @click="loadWorkOrderDetails(item)"
                    >
                        <td>
                            <div class="text-nowrap">{{ item.code }}</div>
                        </td>
                        <td>
                            <div
                                class="d-flex align-items-start"
                                style="gap: 5px"
                            >
                                <div>
                                    <work-order-type-icon
                                        :work-order="item"
                                        style="height: 22px"
                                    />
                                </div>
                                <div class="text-max-2-lines">
                                    {{ item.title }}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="text-truncate">
                                {{ item.assignee?.name || '-' }}
                            </div>
                        </td>
                        <td>
                            <div v-if="item.processingTimeInSeconds">
                                {{
                                    $filters.duration(
                                        item.processingTimeInSeconds,
                                        currentLocale,
                                    )
                                }}
                            </div>
                            <div v-else>-</div>
                        </td>
                        <td>
                            <div
                                class="d-flex align-items-center"
                                style="gap: 1rem"
                            >
                                <icon
                                    v-if="item.status.value === 'resolved'"
                                    icon="cil-check-alt"
                                    size="lg"
                                />
                                <icon
                                    v-if="item.status.value === 'closed'"
                                    icon="cil-x"
                                    size="lg"
                                />
                                <div class="text-nowrap text-bold">
                                    {{
                                        $filters.fullDate(
                                            item.statusChangedAt,
                                            currentLocale,
                                        )
                                    }}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td class="td-empty" colspan="5">
                            <div
                                class="d-flex justify-content-center align-items-center py-5"
                            >
                                <b>{{
                                    trans('workorder.overview.records.empty')
                                }}</b>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tfoot v-if="isLoading">
                    <tr>
                        <td colspan="5">
                            <div
                                class="d-flex justify-content-center align-items-center"
                            >
                                <spinner />
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
            <div ref="landmark" style="height: 20px"></div>
        </div>
    </div>
</template>
<script>
import WorkOrderTypeIcon from '@/Pages/WorkOrders/Modals/WorkOrderTypeIcon.vue';
import { eventBus, events } from '@/eventBus.js';

export default {
    name: 'work-order-records',
    components: { WorkOrderTypeIcon },
    emits: ['click'],
    props: {
        workOrders: {},
        activeFilters: {},
    },
    data() {
        return {
            isLoading: false,
            observer: null,
            items: [],
        };
    },
    created() {
        this.observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.loadMoreItems();
                    }
                });
            },
            {
                root: this.$el,
                threshold: 1.0,
            },
        );
    },
    beforeDestroy() {
        this.observer.disconnect();
    },
    mounted() {
        this.observer.observe(this.$refs.landmark);
        this.items = this.workOrders?.data || [];
    },
    methods: {
        loadMoreItems() {
            const nextPageUrl = this.workOrders?.links.next;

            if (!nextPageUrl || this.isLoading) {
                return;
            }

            this.isLoading = true;

            this.$inertia.get(
                nextPageUrl,
                {},
                {
                    preserveState: true,
                    preserveScroll: true,
                    onSuccess: () => {
                        const keys = this.items.map((el) => el.id);

                        this.workOrders.data.forEach((workOrder) => {
                            if (!keys.includes(workOrder.id)) {
                                this.items.push(workOrder);
                            }
                        });
                    },
                    onFinish: () => {
                        this.isLoading = false;
                    },
                },
            );
        },
        loadWorkOrderDetails(workOrder) {
            eventBus.$emit(events.openWorkOrderDetailsModal, {
                workOrderId: workOrder.id,
            });
        },
    },
};
</script>
