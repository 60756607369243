<template>
    <auth-layout>
        <div>
            <div>
                <div class="row w-100 m-0 min-vh-100">
                    <div
                        class="col-xl-6 col-md-8 d-flex flex-column bg-white overflow-hidden"
                        style="padding: 0 24px"
                    >
                        <language-selector
                            v-if="!isMobile"
                            :use-full-label="true"
                            class="d-flex my-4"
                        />
                        <c-form
                            class="login-form d-flex flex-column justify-content-between gap-5"
                            style="
                                margin: 90px auto 56px;
                                max-width: 464px;
                                width: 100%;
                            "
                            @submit.prevent="submit"
                        >
                            <div>
                                <h1>{{ trans('auth.login.header') }}</h1>
                                <p v-text="trans('auth.login.description')" />
                            </div>
                            <div>
                                <jet-input
                                    id="email"
                                    v-model="form.email"
                                    :horizontal="false"
                                    :isValid="!hasErrors ? null : false"
                                    :placeholder="
                                        trans('auth.general.email.placeholder')
                                    "
                                    autocomplete="username email"
                                    autofocus
                                    class="input-underline mb-7"
                                    prepend-classes="bg-primary text-white border-primary"
                                    required
                                />
                                <jet-input
                                    id="password"
                                    v-model="form.password"
                                    :horizontal="false"
                                    :isValid="!hasErrors ? null : false"
                                    :placeholder="
                                        trans(
                                            'auth.general.password.placeholder',
                                        )
                                    "
                                    autocomplete="current-password"
                                    class="input-underline mb-4"
                                    required
                                    type="password"
                                />
                                <jet-checkbox
                                    v-model:checked="form.remember"
                                    :label="trans('auth.login.remember')"
                                    class="p4"
                                    name="remember"
                                />
                                <row class="mb-10">
                                    <column>
                                        <jet-validation-errors />
                                    </column>
                                </row>
                                <div class="d-flex justify-content-center">
                                    <jet-button
                                        :disabled="
                                            form.processing || !form.isDirty
                                        "
                                        :is-processing="form.processing"
                                        class="w-100"
                                        color="primary"
                                        >{{ trans('auth.login.action.login') }}
                                    </jet-button>
                                </div>
                                <div
                                    class="d-flex justify-content-start mt-3 p4"
                                >
                                    <Link
                                        v-if="canResetPassword"
                                        :href="$route('password.request')"
                                        >{{
                                            trans('auth.login.forgot_password')
                                        }}
                                    </Link>
                                </div>
                            </div>
                        </c-form>
                        <div
                            v-if="!isMobile"
                            class="slide-container slide-container-left"
                        >
                            <div class="mask-left">
                                <ul class="list-unstyled slide-track">
                                    <li class="slide">
                                        <img
                                            :src="
                                                cdnAssetPath('images/bag.jpg')
                                            "
                                        />
                                    </li>
                                    <li class="slide">
                                        <img
                                            :src="
                                                cdnAssetPath(
                                                    'images/suitcase.jpg',
                                                )
                                            "
                                        />
                                    </li>
                                    <li class="slide">
                                        <img
                                            :src="
                                                cdnAssetPath(
                                                    'images/backpack_front.jpg',
                                                )
                                            "
                                        />
                                    </li>
                                    <li class="slide">
                                        <img
                                            :src="
                                                cdnAssetPath(
                                                    'images/trolley.jpg',
                                                )
                                            "
                                        />
                                    </li>
                                    <li class="slide">
                                        <img
                                            :src="
                                                cdnAssetPath(
                                                    'images/backpack_side.jpg',
                                                )
                                            "
                                        />
                                    </li>
                                    <li class="slide">
                                        <img
                                            :src="
                                                cdnAssetPath('images/bag.jpg')
                                            "
                                        />
                                    </li>
                                    <li class="slide">
                                        <img
                                            :src="
                                                cdnAssetPath(
                                                    'images/suitcase.jpg',
                                                )
                                            "
                                        />
                                    </li>
                                    <li class="slide">
                                        <img
                                            :src="
                                                cdnAssetPath(
                                                    'images/backpack_front.jpg',
                                                )
                                            "
                                        />
                                    </li>
                                    <li class="slide">
                                        <img
                                            :src="
                                                cdnAssetPath(
                                                    'images/trolley.jpg',
                                                )
                                            "
                                        />
                                    </li>
                                    <li class="slide">
                                        <img
                                            :src="
                                                cdnAssetPath(
                                                    'images/backpack_side.jpg',
                                                )
                                            "
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div
                        class="col-xl-6 col-md-4 d-none d-md-block overflow-hidden p-3"
                    >
                        <div
                            class="d-flex flex-column align-items-center"
                            style="min-height: calc(100vh - 56px)"
                        >
                            <div class="text-white logo-right">
                                <LogoCurie style="max-width: 100%" />
                            </div>

                            <div v-if="!isMobile" class="slide-container">
                                <div class="mask-right">
                                    <ul class="list-unstyled slide-track">
                                        <li class="slide">
                                            <img
                                                :src="
                                                    cdnAssetPath(
                                                        'images/slideshow02/bag_x.png',
                                                    )
                                                "
                                            />
                                        </li>
                                        <li class="slide">
                                            <img
                                                :src="
                                                    cdnAssetPath(
                                                        'images/slideshow02/suitcase_x.png',
                                                    )
                                                "
                                            />
                                        </li>
                                        <li class="slide">
                                            <img
                                                :src="
                                                    cdnAssetPath(
                                                        'images/slideshow02/backpack_front_x.png',
                                                    )
                                                "
                                            />
                                        </li>
                                        <li class="slide">
                                            <img
                                                :src="
                                                    cdnAssetPath(
                                                        'images/slideshow02/trolley_x.png',
                                                    )
                                                "
                                            />
                                        </li>
                                        <li class="slide">
                                            <img
                                                :src="
                                                    cdnAssetPath(
                                                        'images/slideshow02/backpack_side_x.png',
                                                    )
                                                "
                                            />
                                        </li>
                                        <li class="slide">
                                            <img
                                                :src="
                                                    cdnAssetPath(
                                                        'images/slideshow02/bag_x.png',
                                                    )
                                                "
                                            />
                                        </li>
                                        <li class="slide">
                                            <img
                                                :src="
                                                    cdnAssetPath(
                                                        'images/slideshow02/suitcase_x.png',
                                                    )
                                                "
                                            />
                                        </li>
                                        <li class="slide">
                                            <img
                                                :src="
                                                    cdnAssetPath(
                                                        'images/slideshow02/backpack_front_x.png',
                                                    )
                                                "
                                            />
                                        </li>
                                        <li class="slide">
                                            <img
                                                :src="
                                                    cdnAssetPath(
                                                        'images/slideshow02/trolley_x.png',
                                                    )
                                                "
                                            />
                                        </li>
                                        <li class="slide">
                                            <img
                                                :src="
                                                    cdnAssetPath(
                                                        'images/slideshow02/case_x.png',
                                                    )
                                                "
                                            />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </auth-layout>
</template>

<script setup>
import JetButton from '@/Jetstream/Button.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import JetValidationErrors from '@/Jetstream/ValidationErrors.vue';
import AuthLayout from '@/Layouts/AuthLayout.vue';
import JetInput from '@/Jetstream/Input.vue';
import LogoCurie from '../../../icons/LogoCurie.vue';
import { isMobile } from '@/Utils/UseMedia';
import { useForm, usePage } from '@inertiajs/vue3';
import { route } from 'ziggy-js';
import { computed } from 'vue';
import LanguageSelector from '@/Containers/LanguageSelector.vue';

const props = defineProps({
    canResetPassword: Boolean,
    status: String,
});

const page = usePage();

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const errors = computed(() => {
    return page.props.errors;
});
const hasErrors = computed(() => {
    return Object.keys(errors).length > 0;
});

const version = computed(() => {
    return page.props.version?.semver;
});

const commit = computed(() => {
    return page.props.version?.commit_hash;
});

const curieTechUrl = computed(() => {
    return page.props.curie_tech_url;
});

const submit = () =>
    form
        .transform((data) => ({
            ...data,
            remember: form.remember ? 'on' : '',
        }))
        .post(route('login'), {
            onFinish: () => form.reset('password'),
        });
</script>
<style lang="scss">
.login-form {
    position: relative;
    margin-top: 90px;
    z-index: 2;
}

@media (max-width: 767px) {
    .login-form {
        min-height: calc(100vh - 90px - 56px);
    }
}

.curie-logo {
    position: relative;
    z-index: 2;
}

.fill-primary {
    path[fill],
    polygon[fill] {
        fill: #2c54ea !important;
    }
}

.version {
    position: relative;
    z-index: 2;
}

.mask-left {
    @media (min-width: 576px) {
        clip-path: inset(0 33.3333vw 0 0);
    }

    @media (min-width: 1200px) {
        clip-path: inset(0 50vw 0 0);
    }
}

.mask-right {
    @media (min-width: 576px) {
        clip-path: inset(0 0 0 66.6666vw);
    }

    @media (min-width: 1200px) {
        clip-path: inset(0 0 0 50vw);
    }
}

.slide-container {
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 30px;
    max-height: 150px;

    img {
        max-height: 150px;
    }

    @media (max-width: 767px) {
        display: none;
    }
}

.slide-container-left {
    @media (max-height: 800px) {
        opacity: 0;
    }
}

$slides: 5;
$slide_width: 250px;
$slide_margin: 200px;

.slide-track {
    animation: slide 50s linear infinite;
    display: flex;
    width: calc(($slide_width + 2 * $slide_margin) * $slides * 2);
}

.slide {
    width: $slide_width;
    margin: 0 $slide_margin;
    text-align: center;
}

@keyframes slide {
    0% {
        transform: translateX(
            calc(($slide_width + 2 * $slide_margin) * $slides * -1)
        );
    }
    100% {
        transform: translateX(0);
    }
}

.logo-right svg {
    width: 188px;
}

@media (min-width: 1200px) {
    .logo-right {
        margin-top: 100px;
    }

    .logo-right svg {
        width: 311px;
    }
}
</style>
