<template>
    <div
        :class="{ clickable: notification.payload?.url }"
        class="dropdown-item px-2 d-flex justify-content-between align-items-start"
        style="gap: 15px; cursor: default"
        @click="handleClick"
    >
        <component
            :is="notificationTypeWrapper"
            :icon="icon"
            :notification="notification"
        />
        <div>
            <div
                class="d-flex justify-content-end align-items-center"
                style="height: 22.5px"
            >
                <em
                    v-c-tooltip="{
                        content: trans(
                            'notification_center.tooltip.mark_as_read',
                        ),
                        popperOptions: {
                            positionFixed: true,
                        },
                    }"
                    class="status-badge bg-primary"
                    @click.stop.prevent="markAsRead(notification)"
                ></em>
            </div>
            <small class="d-block text-muted">{{
                $filters.relativeDate(notification.createdAt, currentLocale)
            }}</small>
        </div>
    </div>
</template>
<script>
import AssetOperabilityChanged from './NotificationTypes/AssetOperabilityChanged.vue';
import DefaultNotificationType from './NotificationTypes/DefaultNotificationType.vue';
import MaintenanceOverdue from './NotificationTypes/MaintenanceOverdue.vue';
import AssetStatusChanged from './NotificationTypes/AssetStatusChanged.vue';
import WorkOrderNotification from './NotificationTypes/WorkOrderNotification.vue';
import AirportUserSynched from '@/Components/NotificationCenter/NotificationTypes/AirportUserSynched.vue';
import { $http } from '@/bootstrap.js';
import ExportStatusChanged from '@/Components/NotificationCenter/NotificationTypes/ExportStatusChanged.vue';

export default {
    name: 'Notification',
    props: {
        notification: {},
    },
    components: {
        'asset.operability.changed': AssetOperabilityChanged,
        'asset.status.changed': AssetStatusChanged,
        'export.status_changed': ExportStatusChanged,
        'location.access.denied': AirportUserSynched,
        'location.access.granted': AirportUserSynched,
        'maintenance.overdue': MaintenanceOverdue,
        'workorder.created': WorkOrderNotification,
        'workorder.resolved': WorkOrderNotification,
        'workorder.closed': WorkOrderNotification,
        'workorder.assigned': WorkOrderNotification,
        'workorder.done': WorkOrderNotification,
        'workorder.overdue': WorkOrderNotification,
        defaultNotification: DefaultNotificationType,
    },
    computed: {
        notificationTypeWrapper() {
            return (
                this.getSpecificNotificationComponent(this.notification.type) ||
                'defaultNotification'
            );
        },
        icon() {
            switch (this.notification.type) {
                case 'asset.deleted':
                    return 'cil-trash';
                default:
                    return null;
            }
        },
    },
    methods: {
        markAsRead(notification) {
            if (!notification?.id) {
                return;
            }

            $http
                .post(this.route('notifications.mark_as_read'), {
                    id: notification.id,
                })
                .finally(() => {
                    this.$emit('read', notification);
                });
        },
        getSpecificNotificationComponent(type) {
            return this.$options.components[this.notification.type];
        },
        handleClick() {
            if (!!this.notification.payload?.url) {
                this.$inertia.visit(this.notification.payload?.url);
            }
            this.markAsRead();
        },
    },
};
</script>
<style scoped>
.status-badge {
    width: 10px;
    height: 10px;
    display: block;
    border: 1px solid #fff;
    border-radius: 50em;
    transition: transform ease-in-out 0.3s;
    z-index: 9999;
}

.status-badge:hover,
.status-badge:focus {
    transform: scale(1.5);
    background: #00A37B !important;
    cursor: pointer;
}
</style>
