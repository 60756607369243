<template>
    <dialog-modal
        v-if="shouldRender && hasAnyPermission('workorder.read')"
        :show="show"
        max-width="lg"
    >
        <template #title>{{ trans('workorder.modal.details.title') }}</template>
        <div v-if="isLoading" class="d-flex justify-content-center mt-4">
            <spinner size="md" />
        </div>
        <div v-else-if="!!error">
            <alert color="danger">
                {{ error }}
            </alert>
        </div>
        <div v-else>
            <work-order-type-icon
                :text="workOrder?.code"
                :work-order="workOrder"
                class="mt-2 mb-1"
            />
            <h3 class="work-order-title mb-4">
                <inline-editable-field
                    v-model="form.title"
                    :editable="isEditable"
                    :invalid-feedback="form.errors.title"
                    :placeholder="trans('workorder.form.title.placeholder')"
                />
            </h3>
            <work-order-info
                :assignees="assignees"
                :form="form"
                :is-editable="isEditable"
                :priorities="priorities"
                :statuses="statuses"
                :work-order="workOrder"
            />

            <h4 class="section-title mb-3">
                {{ trans('workorder.details.section.asset_details.title') }}
            </h4>
            <asset-details :asset="workOrder?.asset" />

            <h4 class="section-title mb-3">
                {{ trans('workorder.details.section.description.title') }}
            </h4>
            <inline-editable-field
                v-model="form.description"
                :bordered="true"
                :editable="isEditable"
                :invalid-feedback="form.errors.description"
                :placeholder="trans('workorder.form.description.placeholder')"
                :rows="3"
                class="mb-4"
            />

            <dl class="mb-4">
                <div class="row">
                    <dt class="col-3 font-weight-normal text-truncate">
                        {{
                            trans('workorder.details.workorder.ticket_id.label')
                        }}
                    </dt>
                    <dd class="col-9 text-muted">
                        <inline-editable-field
                            v-model="form.ticketId"
                            :editable="isEditable"
                            :invalid-feedback="form.errors.ticketId"
                            :line-height="22.5"
                            :placeholder="
                                trans('workorder.form.ticket_id.placeholder')
                            "
                            :resizable="false"
                        />
                    </dd>
                </div>
                <div class="row">
                    <dt class="col-3 font-weight-normal text-truncate">
                        {{
                            trans(
                                'workorder.details.workorder.ticket_link.label',
                            )
                        }}
                    </dt>
                    <dd class="col-9 text-muted">
                        <inline-editable-field
                            v-model="form.ticketLink"
                            :editable="isEditable"
                            :invalid-feedback="form.errors.ticketLink"
                            :line-height="22.5"
                            :placeholder="
                                trans('workorder.form.ticket_link.placeholder')
                            "
                            :resizable="false"
                        />
                    </dd>
                </div>
            </dl>
            <h4 class="section-title mb-3">
                {{ trans('workorder.details.section.attachments.title') }}
            </h4>
            <div class="mb-4">
                <file-list
                    :attachments="workOrder?.attachments"
                    :can-delete="
                        hasAnyPermission('workorder.attachment.delete')
                    "
                    :can-edit="hasAnyPermission('workorder.attachment.update')"
                    :can-read="
                        hasAnyPermission([
                            'workorder.attachment.index',
                            'workorder.attachment.read',
                        ])
                    "
                    :readonly="false"
                    class="mb-3"
                    @deleted="loadFormData(false)"
                />
                <FileUpload
                    v-if="isEditable"
                    v-model="form.attachments"
                    :class="{ 'is-invalid': form.errors.attachments }"
                    @upload-started="uploadInProgress = true"
                    @upload-completed="uploadInProgress = false"
                />
                <div class="invalid-feedback">
                    {{ form.errors.attachments }}
                </div>
            </div>
            <div v-if="workOrder && show">
                <h4 class="section-title mb-3">
                    {{ trans('workorder.details.section.comments.title') }}
                </h4>
                <comments
                    :create-comment-url="
                        route('workorders.comments.store', {
                            workorder: workOrder.id,
                        })
                    "
                    :editable="true"
                    :get-comments-url="
                        route('workorders.comments.index', {
                            workorder: workOrder,
                        })
                    "
                    :initial-comments="workOrder?.comments"
                    :placeholder="
                        trans('workorder.details.section.comments.placeholder')
                    "
                    :show-warning="showCommentWarning"
                    @input="updateComment"
                />
            </div>
        </div>

        <template #footer>
            <jet-button
                v-if="isEditable"
                :disabled="uploadInProgress"
                type="button"
                variant="tertiary"
                @click.stop="ensureCommentPosted(closeModal)"
                >{{ trans('general.action.cancel') }}
            </jet-button>
            <jet-button
                v-else
                :disabled="uploadInProgress"
                color="secondary"
                @click.stop="ensureCommentPosted(closeModal)"
                >{{ trans('general.action.close') }}
            </jet-button>
            <jet-button
                v-if="!error && isEditable"
                :disabled="!form.isDirty || uploadInProgress"
                :is-processing="form.processing"
                color="primary"
                @click.stop="ensureCommentPosted(submitForm)"
            >
                {{ trans('general.action.save') }}
            </jet-button>
        </template>
    </dialog-modal>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';
import DialogModal from '@/Jetstream/DialogModal.vue';
import AssetDetails from '@/Pages/WorkOrders/WorkOrderForm/AssetDetails.vue';
import WorkOrderInfo from '@/Pages/WorkOrders/Modals/WorkOrderInfo.vue';
import FileUpload from '@/Components/FileUpload.vue';
import InlineEditableField from '@/Pages/WorkOrders/Modals/InlineEditableField.vue';
import FileList from '@/Components/FileList.vue';
import Comments from '@/Components/Comments.vue';
import WorkOrderTypeIcon from '@/Pages/WorkOrders/Modals/WorkOrderTypeIcon.vue';
import Alert from '@/Components/Alert.vue';
import { eventBus, events } from '@/eventBus.js';
import { $http } from '@/bootstrap.js';

export default {
    components: {
        Alert,

        WorkOrderTypeIcon,
        Comments,
        FileList,
        InlineEditableField,
        FileUpload,
        WorkOrderInfo,
        AssetDetails,
        DialogModal,
        JetButton,
    },
    data() {
        return {
            workOrderId: null,
            isLoading: false,
            error: null,
            initialStatus: null,
            formData: null,
            comment: null,
            showCommentWarning: false,
            uploadInProgress: false,
            shouldRender: false,
            show: false,
            form: this.$inertia.form({
                status: null,
                priority: 'medium',
                assignee: 'unassigned',
                startDate: null,
                dueDate: null,
                title: null,
                description: null,
                ticketId: null,
                ticketLink: null,
                attachments: [],
            }),
        };
    },
    computed: {
        workOrder() {
            return this.formData?.workOrder;
        },
        statuses() {
            return this.formData?.statuses ?? [];
        },
        priorities() {
            return this.formData?.priorities ?? [];
        },
        assignees() {
            return (
                this.formData?.assignees?.map((assignee) => {
                    return {
                        group: assignee.group,
                        data: assignee.data.map((data) => ({
                            label: data.name,
                            value: data.id,
                        })),
                    };
                }) ?? []
            );
        },
        isEditable() {
            return (
                (this.formData?.workOrder.isEditable &&
                    this.hasAnyPermission('workorder.update')) ||
                false
            );
        },
    },
    mounted() {
        eventBus.$on([events.openWorkOrderDetailsModal], ({ workOrderId }) => {
            if (!workOrderId) {
                return;
            }

            this.workOrderId = workOrderId;
            this.shouldRender = true;
            this.loadFormData();

            this.$nextTick(() => {
                this.show = true;
            });
        });
    },
    beforeUnmount() {
        eventBus.$off([events.openWorkOrderDetailsModal]);
    },
    methods: {
        submitForm() {
            if (!this.workOrderId || !this.isEditable) {
                return;
            }

            this.form
                .transform((data) => {
                    if (!data.attachments) {
                        delete data.attachments;
                    }

                    return data;
                })
                .put(this.route('workorders.update', this.workOrderId), {
                    preserveState: false,
                    onSuccess: () => {
                        this.closeModal();

                        eventBus.$emit(
                            events.workOrderUpdated,
                            this.workOrderId,
                        );

                        if (this.initialStatus !== this.form.status) {
                            eventBus.$emit(events.workOrderStatusUpdated, {
                                workOrderId: this.workOrder.id,
                                status: this.form.status,
                            });
                        }
                    },
                });
        },
        loadFormData(isLoading = true) {
            this.reset();

            if (!this.workOrderId || this.isLoading) {
                return;
            }

            this.isLoading = isLoading;

            $http
                .get(
                    this.route('workorders.edit', {
                        workorder: this.workOrderId,
                    }),
                )
                .then((resp) => {
                    this.formData = resp.data;
                    const { workOrder } = resp.data;
                    this.error = null;

                    this.form = this.$inertia.form({
                        status: workOrder.status?.value,
                        priority: workOrder.priority?.value,
                        assignee: workOrder.assignee?.id || 'unassigned',
                        startDate: workOrder.startDate,
                        dueDate: workOrder.dueDate,
                        title: workOrder.title,
                        description: workOrder.description,
                        ticketId: workOrder.ticketId,
                        ticketLink: workOrder.ticketLink,
                        attachments: [],
                    });

                    this.initialStatus = workOrder.status?.value;
                })
                .catch((error) => {
                    switch (error.response.status) {
                        case 404:
                            this.error = this.trans(
                                'workorder.modal.details.error.not_found',
                            );
                            break;
                        default:
                            this.error = error.response.data.error;
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        reset() {
            this.form.reset();
            this.form.clearErrors();
            this.formData = null;
            this.error = null;
            this.initialStatus = null;
            this.uploadInProgress = false;
            this.comment = null;
            this.showCommentWarning = false;
        },
        updateComment(comment) {
            this.comment = comment;
            if (!comment) {
                this.showCommentWarning = false;
            }
        },
        closeModal() {
            this.show = false;

            // Remove workorder query param from URL
            // to prevent loading the same workorder again
            let url = new URL(window.location.href);
            url.searchParams.delete('workorder');
            window.history.pushState({}, '', url.toString());

            setTimeout(() => {
                this.shouldRender = false;
            }, 300);
        },
        ensureCommentPosted(callback) {
            if (!this.comment) {
                callback();
                return;
            }

            if (this.showCommentWarning) {
                this.$nextTick(() => {
                    callback();
                });
            }

            this.showCommentWarning = !!this.comment;
        },
    },
};
</script>
<style scoped>
.work-order-title {
    font-size: 20px;
    line-height: 20px;
}

.section-title {
    font-size: 16px;
}
</style>
