import { camelCase } from 'lodash';
import { usePage } from '@inertiajs/vue3';

const page = usePage();

export const hasFeature = function (requiredFeatures) {
    if (!Array.isArray(requiredFeatures)) {
        requiredFeatures = [requiredFeatures];
    }

    const availableFeatures = page.props.auth?.user?.features;

    if (!availableFeatures) {
        return false;
    }

    return requiredFeatures.every((feature) => {
        feature = camelCase(feature);

        return (
            availableFeatures.hasOwnProperty(feature) &&
            availableFeatures[feature]
        );
    });
};

export default {
    methods: {
        hasFeature,
    },
};
