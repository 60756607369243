<template>
    <jet-select
        :disabled="disabled"
        :is-processing="isProcessing"
        :model-value="modelValue"
        :options="usageCategories"
        :resettable="false"
        class="asset-usage-category"
        color="primary"
        @update:model-value="$emit('update:modelValue', $event)"
    >
    </jet-select>
</template>
<script>
import JetSelect from '@/Jetstream/Select.vue';

export default {
    name: 'usage-category-selection-dropdown',
    components: { JetSelect },
    emits: ['update:modelValue'],
    props: {
        disabled: { type: Boolean, default: false },
        modelValue: { type: String },
        usageCategories: { type: Array, default: () => [] },
        isProcessing: { type: Boolean, default: false },
    },
};
</script>
