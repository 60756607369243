<template>
    <app-layout>
        <asset-details-header />

        <div class="d-flex justify-content-between align-items-top mt-6">
            <activity-filterbar
                :query-params="activeQueryParams"
                class="w-100"
            />
            <teleport
                v-if="hasAnyPermission('activity.export')"
                :disabled="isDesktop"
                defer
                to="#page-header-action"
            >
                <export-menu
                    :active-query-params="activeQueryParams"
                    :allowed-exports="['csv', 'xlsx']"
                    :no-item-selected-tooltip="
                        trans(
                            'activities.overview.export.tooltip.no_item_selected',
                        )
                    "
                    :non-query-filters="{ assetId: asset.id }"
                    :selection="selection"
                    routeName="activities.export"
                />
            </teleport>
        </div>

        <div class="py-3">
            <activity-log
                :activities="activities"
                :fields="fields"
                :query-params="activeQueryParams"
                context="asset"
            />
        </div>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import AssetDetailsHeader from '@/Pages/Assets/Details/AssetDetailsHeader.vue';
import ActivityLog from '@/Components/ActivityLog/ActivityLog.vue';
import Pagination from '@/Components/Pagination.vue';
import JetButton from '@/Jetstream/Button.vue';
import ActivityFilterbar from '@/Pages/Activities/ActivityFilterbar.vue';
import useQueryParams from '@/Utils/UseQueryParams';
import { usePage } from '@inertiajs/vue3';
import { useRoute } from 'ziggy-js';
import ExportMenu from '@/Components/ExportMenu.vue';
import { isDesktop } from '@/Utils/UseMedia';
import useSelectedItems from '@/Utils/UseSelectedItems';
import { hasAnyPermission } from '@/mixins/hasAnyPermission.js';
import { computed } from 'vue';
import { trans } from '@/mixins/i18n.js';

export default {
    methods: { trans, hasAnyPermission },
    components: {
        ExportMenu,
        ActivityFilterbar,
        JetButton,
        Pagination,
        AppLayout,
        AssetDetailsHeader,
        ActivityLog,
    },

    setup() {
        const page = usePage();
        const route = useRoute();

        const activitiesMeta = computed(
            () => page.props.activities?.meta || {},
        );
        const selection = useSelectedItems('asset-activities', activitiesMeta);

        const activeQueryParams = useQueryParams({
            paramsWithDefaults: {
                search: null,
                start: null,
                end: null,
                users: [],
                sort: { column: 'date', asc: false },
            },
            route: route('assets.activities.index', page.props.asset),
            options: {
                only: ['activities'],
            },
            additionalData: () => ({
                selection: selection.getSelectionAsQueryParams(),
            }),
            onLoadSuccess: (data) => {
                selection.setSelection(data.props.activities.meta.selection);
            },
        });

        return {
            activeQueryParams,
            isDesktop,
            selection,
        };
    },

    data() {
        return {
            fields: [
                {
                    key: 'date',
                    label: this.trans('activities.overview.cell.date'),
                    _style: 'width: 120px; min-width: 120px; max-width: 120px',
                },
                {
                    key: 'causer',
                    label: this.trans('activities.overview.cell.user'),
                    _style: 'width: 200px; min-width: 200px; max-width: 200px',
                },
                {
                    key: 'event',
                    label: this.trans('activities.overview.cell.event'),
                    sorter: false,
                },
            ],
        };
    },

    computed: {
        asset() {
            return this.$page.props.asset;
        },
        activities() {
            return this.$page.props.activities;
        },
    },
};
</script>
