<template>
    <auth-layout>
        <container class="align-items-center min-vh-100">
            <row class="min-vh-100 justify-content-center align-items-center">
                <column md="6">
                    <card class="p-4 m-0 bg-white">
                        <c-form @submit.prevent="submit">
                            <h1 v-if="isReset">
                                {{ trans('auth.reset_password.header') }}
                            </h1>
                            <h1 v-else>
                                {{ trans('auth.set_password.header') }}
                            </h1>
                            <p class="text-muted">
                                {{ trans('auth.reset_password.description') }}
                            </p>
                            <div>
                                <jet-input
                                    id="email"
                                    :horizontal="false"
                                    :model-value="email"
                                    :placeholder="trans('auth.general.email')"
                                    :readonly="true"
                                >
                                    <template #prepend>
                                        <icon icon="cil-user" />
                                    </template>
                                </jet-input>
                            </div>
                            <jet-input
                                id="password"
                                v-model="form.password"
                                :horizontal="false"
                                :invalid-feedback="hasErrors ? ' ' : ''"
                                :isValid="!hasErrors"
                                :placeholder="trans('auth.general.password')"
                                autocomplete="new-password"
                                autofocus
                                required
                                type="password"
                            >
                                <template #prepend>
                                    <icon icon="cil-lock-locked" />
                                </template>
                            </jet-input>
                            <jet-input
                                id="confirm-password"
                                v-model="form.password_confirmation"
                                :horizontal="false"
                                :invalid-feedback="passwordError"
                                :isValid="!hasErrors ? null : false"
                                :placeholder="
                                    trans('auth.general.password_confirmation')
                                "
                                autocomplete="new-password"
                                required
                                type="password"
                            >
                                <template #prepend>
                                    <icon icon="cil-lock-locked" />
                                </template>
                            </jet-input>
                            <div class="d-lg-flex mt-4" style="gap: 20px">
                                <password-rule-validation-errors
                                    :errors="
                                        $page.props.errorBags.default?.password
                                    "
                                    :show-status="formSubmitted"
                                    class="mb-3"
                                />
                                <div
                                    class="d-flex flew-column justify-content-start align-items-start"
                                >
                                    <jet-button
                                        :is-processing="form.processing"
                                        class="flex-grow-0"
                                        >{{
                                            trans(
                                                isReset
                                                    ? 'auth.reset_password.action.submit'
                                                    : 'auth.set_password.action.submit',
                                            )
                                        }}
                                    </jet-button>
                                </div>
                            </div>
                        </c-form>
                    </card>
                </column>
            </row>
        </container>
    </auth-layout>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';
import AuthLayout from '@/Layouts/AuthLayout.vue';
import PasswordValidationErrors from '@/Components/PasswordValidationErrors.vue';
import JetInput from '@/Jetstream/Input.vue';
import PasswordRuleValidationLine from '@/Pages/Auth/PasswordRuleValidationLine.vue';
import Container from '@/Components/Container.vue';
import Card from '@/Components/Card.vue';

export default {
    components: {
        Card,
        Container,
        PasswordRuleValidationLine,
        JetInput,
        PasswordRuleValidationErrors: PasswordValidationErrors,
        JetButton,
        AuthLayout,
    },

    props: {
        email: String,
        token: String,
        isReset: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            formSubmitted: false,
            form: this.$inertia.form({
                email: this.email,
                token: this.token,
                password: '',
                password_confirmation: '',
            }),
        };
    },

    computed: {
        hasErrors() {
            return this.$page.props.errorBags.default?.password?.length > 0;
        },
        passwordRuleValidationMessages() {
            return (
                Object.values(this.$page.props.passwordValidationMessages) || []
            );
        },
        passwordError() {
            if (!this.hasErrors) {
                return '';
            }

            // Exclude password rule validation errors as they are
            // presented below the form element
            if (
                this.passwordRuleValidationMessages.includes(
                    this.$page.props.errors.password,
                )
            ) {
                return ' '; // Space required to trigger invalid state
            }

            return this.$page.props.errors.password;
        },
    },

    methods: {
        submit() {
            this.form.post(this.route('password.update'), {
                onFinish: () => {
                    this.form.reset('password', 'password_confirmation');
                    this.formSubmitted = true;
                },
            });
        },
    },
};
</script>
