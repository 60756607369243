<template>
    <div :style="calculatedStyle" class="bulk-action-overlay">
        <div
            :class="{ 'max-w-vw': jobName }"
            class="d-flex justify-content-between align-items-center max-w"
        >
            <div class="d-flex align-items-center" style="min-width: 0">
                <jet-select
                    v-model="jobName"
                    :horizontal="false"
                    :inline="true"
                    :options="availableBatchJobs"
                    form-group-classes="mb-0"
                    style="min-width: 0"
                />
                <div class="mx-2 text-nowrap">
                    for
                    {{
                        selection.areAllItemsSelected.value
                            ? 'all'
                            : selection.selectedItemsCount
                    }}
                    asset(s)
                </div>
            </div>
            <div>
                <jet-button
                    v-c-tooltip="trans('general.action.cancel')"
                    :class="{
                        'd-none': jobName && selection.selectedItemsCount,
                    }"
                    class="text-white d-md-block btn-icon"
                    icon="cil-x"
                    @click.stop="resetForm"
                />
                <jet-button
                    v-if="jobName"
                    :class="{
                        'd-sm-block': jobName && selection.selectedItemsCount,
                        'd-none': !selection.selectedItemsCount,
                    }"
                    class="text-white d-md-none btn-icon"
                    @click.stop="toggleOverlay"
                >
                    <icon
                        v-if="!isCollapsed"
                        icon="cil-chevron-bottom"
                        size="sm"
                    />
                    <icon v-else icon="cil-chevron-top" size="sm" />
                </jet-button>
            </div>
        </div>
        <div
            v-if="jobName && selection.selectedItemsCount && selectedJob"
            :class="{ 'd-none': isCollapsed }"
            class="d-md-flex mt-3 mt-md-2 justify-content-between align-items-center d-md-block"
            style="gap: 10px"
        >
            <asset-cadence-configuration
                v-if="selectedJob.customForm === 'asset-cadence-configuration'"
                :config="selectedJob.config"
                @update="updatePayload($event)"
            />
            <CustomForm
                v-else
                :config="selectedJob.config"
                @update="updatePayload"
            />
            <div
                class="d-flex align-items-center justify-content-between ml-md-3 mt-3 mt-md-0"
            >
                <jet-button
                    :disabled="!submittable"
                    :is-processing="isRequestInProgress"
                    color="secondary"
                    @click="triggerBatchJob"
                >
                    <span>{{ selectedJob.actionButtonTitle }}</span>
                </jet-button>
            </div>
        </div>
    </div>
</template>
<script>
import JetSelect from '@/Jetstream/Select.vue';
import AssetCadenceConfiguration from './AssetCadenceConfiguration.vue';
import CustomForm from '@/Pages/Assets/BatchJobs/CustomForm.vue';
import JetButton from '@/Jetstream/Button.vue';
import CIcon from '@coreui/icons-vue';
import Icon from '@/Components/Icon.vue';

export default {
    name: 'BatchJobs',
    components: {
        Icon,
        CIcon,
        JetButton,
        CustomForm,
        AssetCadenceConfiguration,
        JetSelect,
    },
    props: {
        selection: {},
        queryParams: {},
    },
    data() {
        return {
            isCollapsed: false,
            jobName: null,
            payload: null,
            isRequestInProgress: false,
        };
    },
    computed: {
        calculatedStyle() {
            return this.selection && this.selection.selectedItemsCount.value
                ? 'bottom: 10px'
                : 'bottom: -100px';
        },
        submittable() {
            return (
                this.selection &&
                this.selection.selectedItemsCount.value &&
                this.jobName &&
                this.payload &&
                !this.isRequestInProgress
            );
        },
        bulkActions() {
            return this.$page.props.bulkActions;
        },
        availableBatchJobs() {
            return this.bulkActions.map((action) => ({
                label: action.title,
                value: action.name,
            }));
        },
        selectedJob() {
            return this.bulkActions.find((job) => this.jobName === job.name);
        },
    },
    methods: {
        triggerBatchJob() {
            if (this.isRequestInProgress) {
                return;
            }

            this.isRequestInProgress = true;
            let selection = this.selection.selectedItems.value;

            this.$inertia.post(
                this.route('batch.dispatch', this.jobName),
                {
                    selection: {
                        all: selection.all,
                        include: Array.from(selection.include),
                        exclude: Array.from(selection.exclude),
                    },
                    queryParams: this.queryParams || null,
                    payload: this.payload,
                },
                {
                    preserveState: true,
                    onFinish: () => {
                        this.isRequestInProgress = false;
                        this.resetForm();
                    },
                },
            );
        },
        updatePayload(value) {
            this.payload = value;
        },
        toggleOverlay() {
            this.isCollapsed = !this.isCollapsed;
        },
        resetForm() {
            this.payload = null;
            this.jobName = null;
            this.selection.resetSelection();
        },
    },
};
</script>
<style scoped>
.bulk-action-overlay {
    background: #2c54ea;
    color: #fff;
    position: fixed;
    bottom: -100px;
    padding: 10px;
    border-radius: 5px;
    z-index: 999;
    transition: 0.3s bottom ease-in-out;
    max-width: calc(100vw - 30px);
}

:deep(.option-reset) {
    display: none;
}

.max-w-vw {
    max-width: calc(100vw - 30px) !important;
}

.max-w {
    max-width: 100%;
}

:deep(.dropdown-inline .dropdown-toggle) {
    padding-left: 10px !important;
    height: 100%;
}

:deep(.dropdown-menu) {
    max-width: none;
}
</style>
