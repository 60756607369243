<template>
    <div class="mt-3" @input="updateValue($event)">
        <h3>{{ trans('locations.structure.title') }}</h3>
        <p style="margin-bottom: 1.125rem">
            {{ trans('locations.structure.description') }}
        </p>
        <div>
            <card body-classes="py-0" card-classes="mb-3" type="decent">
                <span class="d-inline-flex align-items-center text-primary"
                    >+&nbsp;
                    <jet-button
                        class="btn-text p-0"
                        type="button"
                        variant="tertiary"
                        @click.stop="addLocation(locationId)"
                        >{{ trans('locations.actions.add_specific_location') }}
                    </jet-button></span
                >
            </card>
        </div>
        <jet-form-section
            v-for="specificLocation in locationTree"
            :key="specificLocation.id"
            class="loc-tree"
            type="decent"
        >
            <template #form>
                <div class="d-md-flex" style="gap: 60px">
                    <div
                        class="mb-2 text-nowrap"
                        style="font-weight: 900; line-height: 32px"
                    >
                        {{ trans('locations.specific_location.label')
                        }}<em class="text-danger">*</em>
                    </div>
                    <div class="w-100">
                        <div>
                            <div
                                class="d-flex align-items-center"
                                style="gap: 12px"
                            >
                                <input
                                    :id="`loc-${specificLocation.id}`"
                                    :class="{
                                        'is-invalid': hasError(
                                            specificLocation.id,
                                        ),
                                    }"
                                    :value="
                                        getValue(
                                            specificLocation.id,
                                            specificLocation.name,
                                        )
                                    "
                                    class="form-control"
                                    required
                                    type="text"
                                    @keyup.enter="blurField"
                                />
                                <jet-button
                                    :destructive="true"
                                    class="d-md-none"
                                    type="button"
                                    variant="tertiary"
                                    @click.stop="
                                        deleteLocation(
                                            specificLocation,
                                            'specific_location',
                                        )
                                    "
                                >
                                    <icon name="cil-trash" />
                                </jet-button>
                            </div>
                            <div
                                v-if="hasError(specificLocation.id)"
                                class="text-danger small"
                            >
                                {{ getErrorMessage(specificLocation.id) }}
                            </div>
                        </div>
                        <div>
                            <div
                                :class="{
                                    'border-bottom':
                                        specificLocation.children.length,
                                }"
                                class="mt-3 d-flex align-items-center justify-content-between"
                            >
                                <span
                                    class="d-inline-flex align-items-center text-primary"
                                    >+&nbsp;
                                    <jet-button
                                        class="btn-text p-0"
                                        style="min-width: 0"
                                        type="button"
                                        variant="tertiary"
                                        @click.stop="
                                            addLocation(specificLocation.id)
                                        "
                                        >{{
                                            trans('locations.actions.add_area')
                                        }}
                                    </jet-button></span
                                >
                                <button
                                    v-if="specificLocation.children.length"
                                    class="btn btn-text text-small text-uppercase"
                                    type="button"
                                    @click.stop="
                                        toggleAreas(
                                            specificLocation.children,
                                            areAllAreasCollapsed(
                                                specificLocation.children,
                                            )
                                                ? 'expand'
                                                : 'collapse',
                                        )
                                    "
                                >
                                    <span
                                        v-if="
                                            areAllAreasCollapsed(
                                                specificLocation.children,
                                            )
                                        "
                                        >{{
                                            trans(
                                                'locations.actions.expand_area',
                                            )
                                        }}</span
                                    >
                                    <span v-else>{{
                                        trans('locations.actions.collapse_area')
                                    }}</span>
                                </button>
                            </div>
                            <div
                                v-for="(
                                    area, areaIndex
                                ) in specificLocation.children"
                                class="loc-area d-md-flex mt-md-3 align-items-start"
                                style="gap: 20px"
                            >
                                <div
                                    class="mb-2 d-flex align-items-center clickable"
                                    style="
                                        font-weight: 900;
                                        line-height: 32px;
                                        gap: 6px;
                                    "
                                    @click.stop="toggleAreas([area])"
                                >
                                    <arrow-down
                                        :class="{
                                            collapsed: isAreaCollapsed(area),
                                        }"
                                        class="text-primary loc-collapse-toggle"
                                    />
                                    <span
                                        >{{ trans('locations.area.label') }}
                                        {{ areaIndex + 1 }}</span
                                    >
                                </div>
                                <div class="flex-grow-1">
                                    <div class="position-relative">
                                        <input
                                            :id="`loc-${area.id}`"
                                            :class="{
                                                'is-invalid': hasError(area.id),
                                            }"
                                            :value="
                                                getValue(area.id, area.name)
                                            "
                                            class="form-control"
                                            type="text"
                                            @keyup.enter="blurField"
                                        />
                                        <button
                                            class="btn-icon loc-delete"
                                            type="button"
                                            @click.stop="
                                                deleteLocation(area, 'area')
                                            "
                                        >
                                            <icon name="cil-x-circle" />
                                        </button>
                                    </div>
                                    <div
                                        v-if="hasError(area.id)"
                                        class="text-danger small"
                                    >
                                        {{ getErrorMessage(area.id) }}
                                    </div>

                                    <div
                                        :class="{
                                            collapsed: isAreaCollapsed(area),
                                        }"
                                        class="subareas-wrapper"
                                    >
                                        <div class="loc-subareas">
                                            <div class="loc-subarea">
                                                <span
                                                    class="d-inline-flex align-items-center text-primary loc-leaf"
                                                    >+&nbsp;
                                                    <jet-button
                                                        class="btn-text p-0"
                                                        style="min-width: 0"
                                                        type="button"
                                                        variant="tertiary"
                                                        @click.stop="
                                                            addLocation(area.id)
                                                        "
                                                        >{{
                                                            trans(
                                                                'locations.actions.add_subarea',
                                                            )
                                                        }}
                                                    </jet-button></span
                                                >
                                            </div>
                                            <div
                                                v-for="subArea in limitSubAreas(
                                                    area,
                                                )"
                                                class="loc-subarea"
                                            >
                                                <div
                                                    class="position-relative loc-leaf"
                                                >
                                                    <input
                                                        :id="`loc-${subArea.id}`"
                                                        :class="{
                                                            'is-invalid':
                                                                hasError(
                                                                    subArea.id,
                                                                ),
                                                        }"
                                                        :value="
                                                            getValue(
                                                                subArea.id,
                                                                subArea.name,
                                                            )
                                                        "
                                                        class="form-control pe-5"
                                                        type="text"
                                                        @keyup.enter="blurField"
                                                    />
                                                    <button
                                                        class="btn-icon loc-delete"
                                                        type="button"
                                                        @click.stop="
                                                            deleteLocation(
                                                                subArea,
                                                                'subarea',
                                                            )
                                                        "
                                                    >
                                                        <icon
                                                            name="cil-x-circle"
                                                        />
                                                    </button>
                                                </div>
                                                <div
                                                    v-if="hasError(subArea.id)"
                                                    class="text-danger small ps-4"
                                                >
                                                    {{
                                                        getErrorMessage(
                                                            subArea.id,
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-if="area.children.length > 3"
                                            class="ps-4"
                                        >
                                            <button
                                                class="btn btn-text"
                                                style="gap: 6px"
                                                type="button"
                                                @click.stop="
                                                    toggleSubAreas(area)
                                                "
                                            >
                                                <span
                                                    v-if="
                                                        areAllSubAreasVisible(
                                                            area,
                                                        )
                                                    "
                                                    >{{
                                                        trans(
                                                            'general.action.show_less',
                                                        )
                                                    }}
                                                </span>
                                                <span v-else
                                                    >{{
                                                        trans(
                                                            'general.action.show_all',
                                                        )
                                                    }}
                                                    ({{ area.children.length }})
                                                </span>
                                                <arrow-down
                                                    :class="{
                                                        opposite:
                                                            areAllSubAreasVisible(
                                                                area,
                                                            ),
                                                    }"
                                                    class="text-primary loc-collapse-toggle"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <jet-button
                        :destructive="true"
                        class="d-none d-md-flex"
                        style="min-width: 0"
                        type="button"
                        variant="tertiary"
                        @click.stop="
                            deleteLocation(
                                specificLocation,
                                'specific_location',
                            )
                        "
                    >
                        <icon name="cil-trash" />
                        {{ trans('general.action.delete') }}
                    </jet-button>
                </div>
            </template>
        </jet-form-section>
    </div>
</template>
<script setup>
import JetButton from '@/Jetstream/Button.vue';
import Card from '@/Components/Card.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { router, usePage } from '@inertiajs/vue3';
import { eventBus, events } from '@/eventBus.js';
import ArrowDown from '../../../icons/ArrowDown.vue';
import { $http } from '@/bootstrap.js';

const page = usePage();
const emits = defineEmits(['change']);
const props = defineProps({
    locations: Array,
    locationId: String,
});

let changeSet = new Map();

const addLocation = (parentId) => {
    let itemId = crypto.randomUUID();

    changeSet.set(itemId, {
        id: itemId,
        name: '',
        parentId: parentId,
        children: [],
        exists: false,
    });

    // Update parent location children
    let parentLocation = changeSet.get(parentId);
    if (parentLocation) {
        changeSet.set(parentId, {
            ...parentLocation,
            children: [...parentLocation.children, itemId],
        });
    }

    updateLocationTree();
    emitUpdate();

    nextTick(() => {
        document.getElementById(`loc-${itemId}`)?.focus();
    });
};

const removeLocationRecursive = (id) => {
    let location = changeSet.get(id);
    if (location) {
        location.children.forEach((childId) => {
            removeLocationRecursive(childId);
        });

        changeSet.delete(id);
    }
};

const removeLocationFromParent = (parentId, id) => {
    let parentLocation = changeSet.get(parentId);
    if (parentLocation) {
        changeSet.set(parentId, {
            ...parentLocation,
            children: parentLocation.children.filter(
                (childId) => childId !== id,
            ),
        });
    }
};

const deleteLocation = (loc, type) => {
    // Exit early if location has assigned assets
    if (loc.totalAssetsCount > 0) {
        $http.delete(route('locations.destroy', loc.id)).catch((error) => {
            eventBus.$emit(events.presentFlashMessage, {
                payload: error.response.data,
            });
        });
        return;
    }

    if (changeSet.has(loc.id) && changeSet.get(loc.id).exists === false) {
        removeLocationRecursive(loc.id);
        // Remove location from parent
        removeLocationFromParent(loc.parentId, loc.id);
        updateLocationTree();

        emitUpdate();
        return;
    }

    // Immediately delete w/o confirmation dialog if no assets are assigned
    if (type === 'subarea') {
        router.delete(route('locations.destroy', loc.id), {
            preserveScroll: true,
            only: ['location', 'locationWithChildren', 'flash'],
        });

        return;
    }

    // Show confirmation dialog
    eventBus.$emit(events.openDeleteLocationModal, {
        locationId: loc.id,
        name: loc.name,
        type: type,
    });
};

const updateValue = (event) => {
    let value = event.target.value;
    let id = event.target.id?.replace('loc-', '');

    if (!id) {
        return;
    }

    // Check if element exists in changeSet
    let location = changeSet.get(id);
    if (location) {
        // Update name
        changeSet.set(id, {
            ...location,
            name: value,
        });
    } else {
        changeSet.set(id, {
            id: id,
            name: value,
            children: [],
            exists: true,
        });
    }

    // Remove changeset entry if no changes were made
    if (originalTree.value[id] === value) {
        changeSet.delete(id);
    }

    emitUpdate();
};

const emitUpdate = () => {
    let changes = Array.from(changeSet.values() || []).map((change) => ({
        id: change.id,
        name: change.name,
        parentId: change.parentId,
    }));

    emits('change', changes);
};

const hasError = (locationId) => {
    return !!page.props.errors[locationId];
};

const getErrorMessage = (locationId) => {
    return page.props.errors[locationId]?.[0];
};

const locationTree = ref([]);
const originalTree = computed(() => {
    let obj = {};
    props.locations.forEach((el) => (obj[el.id] = el.name));
    return obj;
});

const updateLocationTree = () => {
    const map = {}; // Create a mapping of each item by its id
    const roots = []; // Array to store root nodes

    const allLocations = [
        ...Array.from(changeSet.values() || []).reverse(), // Reverse map to have new items always on top
        ...props.locations,
    ];

    // Initialize each item with an empty children array
    allLocations.forEach((location) => {
        if (!map[location.id]) {
            map[location.id] = { ...location, children: [] };
        }
    });

    // Build the tree structure
    allLocations.forEach((location) => {
        if (location.parentId === props.locationId) {
            // If location has no parentId, it's a root location
            roots.push(map[location.id]);
        } else {
            // If the location has a parent, add it to the parent's children array
            if (map[location.parentId]) {
                map[location.parentId].children.push(map[location.id]);
            }
        }
    });

    nextTick(() => {
        locationTree.value = roots;
    });
};

const getValue = (id, fallback) => {
    return changeSet.has(id) ? changeSet.get(id).name : fallback;
};

const collapsedAreas = ref(new Set());
const areAllAreasCollapsed = (areas) => {
    let resp = true;
    for (const area of areas) {
        if (!collapsedAreas.value.has(area.id)) {
            resp = false;
            break;
        }
    }
    return resp;
};
const isAreaCollapsed = (area) => {
    return collapsedAreas.value.has(area.id);
};
const toggleAreas = (areas, operation) => {
    for (const area of areas) {
        if (operation === 'expand') {
            collapsedAreas.value.delete(area.id);
        } else if (operation === 'collapse') {
            collapsedAreas.value.add(area.id);
        } else if (collapsedAreas.value.has(area.id)) {
            collapsedAreas.value.delete(area.id);
        } else {
            collapsedAreas.value.add(area.id);
        }
    }
};
const expandedSubAreas = ref(new Set());
const areAllSubAreasVisible = (area) => {
    return expandedSubAreas.value.has(area.id);
};
const toggleSubAreas = (area) => {
    if (expandedSubAreas.value.has(area.id)) {
        expandedSubAreas.value.delete(area.id);
    } else {
        expandedSubAreas.value.add(area.id);
    }
};
const limitSubAreas = (area) => {
    if (expandedSubAreas.value.has(area.id)) {
        return area.children;
    }
    return area.children.slice(0, 3);
};

const blurField = (event) => {
    document.activeElement?.blur();
};

onMounted(() => {
    changeSet.clear();
    updateLocationTree();
});

// Rebuild tree whenever locations change
watch(
    originalTree,
    (newValue) => {
        updateLocationTree();
    },
    { deep: true },
);
</script>
