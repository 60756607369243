<template>
    <section>
        <slot v-if="$slots.title" name="title" />
        <row>
            <column>
                <jet-input
                    v-model="form.petitecIdentifier"
                    :description="
                        trans('assets.details.petitec_identifier.description')
                    "
                    :horizontal="false"
                    :invalidFeedback="form.errors.petitecIdentifier"
                    :label="trans('assets.details.petitec_identifier.label')"
                    :placeholder="
                        trans('assets.details.petitec_identifier.placeholder')
                    "
                    name="petitecIdentifier"
                    style="flex-grow: 1"
                >
                    <template #prepend>
                        <icon
                            icon="cil-qr-code"
                            style="shape-rendering: crispEdges"
                        />
                    </template>
                    <template v-if="hasCameraSupport" #appendButton>
                        <jet-button
                            class="border-primary"
                            type="button"
                            @click="openPetitecQrCodeModal"
                        >
                            {{
                                trans(
                                    'assets.details.petitec_identifier.button.scan',
                                )
                            }}
                        </jet-button>
                    </template>
                </jet-input>
            </column>
        </row>
        <row>
            <column>
                <jet-input
                    v-model="form.serialNumber"
                    :horizontal="false"
                    :invalidFeedback="form.errors.serialNumber"
                    :label="trans('assets.details.serial_number.label')"
                    :placeholder="
                        trans('assets.details.serial_number.placeholder')
                    "
                    :required="true"
                    name="serialNumber"
                />
            </column>
        </row>

        <row>
            <column>
                <jet-input
                    v-model="form.customerTrackingNumber"
                    :horizontal="false"
                    :invalidFeedback="form.errors.customerTrackingNumber"
                    :label="
                        trans('assets.details.customer_tracking_number.label')
                    "
                    :placeholder="
                        trans(
                            'assets.details.customer_tracking_number.placeholder',
                        )
                    "
                    name="customerTrackingNumber"
                />
            </column>
        </row>

        <row>
            <column>
                <jet-textarea
                    v-model="form.description"
                    :horizontal="false"
                    :invalidFeedback="form.errors.description"
                    :label="trans('assets.details.description.label')"
                    :placeholder="
                        trans('assets.details.description.placeholder')
                    "
                    name="description"
                />
            </column>
        </row>

        <location-form-fields
            v-model="form.locationId"
            :errors="form.errors.locationId"
        />

        <row>
            <column>
                <manufacturer-dropdown
                    v-model="form.manufacturer"
                    :invalidFeedback="
                        form.errors.modelId && !form.manufacturer
                            ? trans('validation.required')
                            : undefined
                    "
                    @update:model-value="form.modelId = null"
                />
            </column>
        </row>
        <row>
            <column>
                <designation-dropdown
                    v-model="form.modelId"
                    :invalidFeedback="form.errors.modelId"
                    :manufacturer="form.manufacturer"
                />
            </column>
        </row>
        <row>
            <column>
                <jet-select
                    v-model="form.screeningApplication"
                    :horizontal="false"
                    :invalidFeedback="form.errors.screeningApplication"
                    :label="trans('assets.details.screening_application.label')"
                    :options="screeningApplications"
                    :required="true"
                    name="screeningApplication"
                />
            </column>
        </row>
        <row>
            <column>
                <jet-select
                    v-model="form.critical"
                    :horizontal="false"
                    :invalidFeedback="form.errors.critical"
                    :label="trans('assets.details.criticality.label')"
                    :options="criticalities"
                    :required="true"
                    name="criticality"
                />
            </column>
        </row>
        <row>
            <column>
                <jet-datepicker
                    v-model="form.purchasedAt"
                    :horizontal="false"
                    :invalidFeedback="form.errors.purchasedAt"
                    :label="trans('assets.details.purchased_at.label')"
                    name="purchasedAt"
                />
            </column>
        </row>
        <row>
            <column>
                <jet-datepicker
                    v-model="form.deployedAt"
                    :horizontal="false"
                    :invalidFeedback="form.errors.deployedAt"
                    :label="trans('assets.details.deployed_at.label')"
                    name="deployedAt"
                />
            </column>
        </row>
        <row>
            <column>
                <jet-datepicker
                    v-model="form.warrantyStartDate"
                    :horizontal="false"
                    :invalidFeedback="form.errors.warrantyStartDate"
                    :label="trans('assets.details.warranty_start_date.label')"
                    name="warrantyStartDate"
                />
            </column>
        </row>
        <row>
            <column>
                <jet-datepicker
                    v-model="form.warrantyEndDate"
                    :horizontal="false"
                    :invalidFeedback="form.errors.warrantyEndDate"
                    :label="trans('assets.details.warranty_end_date.label')"
                    :min-date="form.warrantyStartDate"
                    form-group-classes="mb-0"
                    name="warrantyEndDate"
                />
            </column>
        </row>
    </section>
</template>
<script setup>
import JetInput from '@/Jetstream/Input.vue';
import DesignationDropdown from '@/Pages/Assets/Edit/DesignationDropdown.vue';
import ManufacturerDropdown from '@/Pages/Assets/Edit/ManufacturerDropdown.vue';
import JetDatepicker from '@/Jetstream/Datepicker.vue';
import LocationFormFields from '@/Pages/Assets/Edit/LocationFormFields.vue';
import JetSelect from '@/Jetstream/Select.vue';
import JetButton from '@/Jetstream/Button.vue';
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { eventBus, events } from '@/eventBus.js';
import { PetiteCIdScannerType } from '@/Components/PetitecIdScanner.vue';
import JetTextarea from '@/Jetstream/Textarea.vue';

const props = defineProps({
    form: {
        type: Object,
        required: true,
    },
});

const page = usePage();

const types = computed(() => page.props.types.data);
const screeningApplications = computed(
    () => page.props.screeningApplications.data,
);
const criticalities = computed(() => page.props.criticalities.data);

const hasCameraSupport = computed(() => !!navigator.mediaDevices);

const openPetitecQrCodeModal = () => {
    eventBus.$emit(events.openQrCodeScanner, {
        type: PetiteCIdScannerType.scanId,
        onSuccessfulScan: (code, modal) => {
            props.form.petitecIdentifier = code;
            modal.close();
        },
    });
};
</script>
