<template>
    <jet-form-section @submitted="updateProfileInformation">
        <template #title>
            {{ trans('profile.information.header') }}
        </template>

        <template #form>
            <p class="mb-5">{{ trans('profile.information.description') }}</p>
            <!-- Profile Photo -->
            <div
                v-if="$page.props.jetstream.managesProfilePhotos"
                class="form-group form-row"
            >
                <!-- Profile Photo File Input -->
                <input
                    ref="photo"
                    class="d-none"
                    type="file"
                    @change="updatePhotoPreview"
                />

                <label class="col-form-label col-sm-3">{{
                    trans('profile.information.label.photo')
                }}</label>

                <div class="col-sm-9">
                    <!-- Current Profile Photo -->
                    <div v-show="!photoPreview" class="mt-2">
                        <img
                            :alt="user.name"
                            :src="user.profile_photo_url"
                            class="rounded-full h-20 w-20 object-cover"
                        />
                    </div>

                    <!-- New Profile Photo Preview -->
                    <div v-show="photoPreview" class="mt-2">
                        <span
                            :style="
                                'background-size: cover; background-repeat: no-repeat; background-position: center center; background-image: url(\'' +
                                photoPreview +
                                '\');'
                            "
                            class="block rounded-full w-20 h-20"
                        >
                        </span>
                    </div>

                    <jet-button
                        class="mt-2 mr-2"
                        color="secondary"
                        type="button"
                        @click.prevent="selectNewPhoto"
                    >
                        {{ trans('profile.information.action.select_photo') }}
                    </jet-button>

                    <jet-button
                        v-if="user.profile_photo_path"
                        class="mt-2"
                        color="secondary"
                        type="button"
                        @click.prevent="deletePhoto"
                    >
                        {{ trans('profile.information.action.remove_photo') }}
                    </jet-button>

                    <jet-input-error
                        :message="form.errors.photo"
                        class="mt-2"
                    />
                </div>
            </div>

            <!-- Name -->
            <div class="col-span-6 sm:col-span-4">
                <jet-input
                    v-model="form.firstName"
                    :invalidFeedback="form.errors.firstName"
                    :label="trans('users.edit_user.field.first_name.label')"
                    :readonly="!isEditable"
                    autocomplete="given-name"
                />
            </div>

            <div class="col-span-6 sm:col-span-4">
                <jet-input
                    v-model="form.lastName"
                    :invalidFeedback="form.errors.lastName"
                    :label="trans('users.edit_user.field.last_name.label')"
                    :readonly="!isEditable"
                    autocomplete="family-name"
                />
            </div>

            <!-- Email -->
            <div class="col-span-6 sm:col-span-4">
                <jet-input
                    v-model="form.email"
                    :invalidFeedback="form.errors.email"
                    :label="trans('profile.information.label.email')"
                    :readonly="!isEditable"
                    autocomplete="email"
                />
            </div>
        </template>

        <template #actions>
            <jet-action-message :on="form.recentlySuccessful" class="mr-3">
                {{ trans('general.message.saved') }}
            </jet-action-message>

            <jet-button
                v-if="isEditable"
                :disabled="!form.isDirty"
                :is-processing="form.processing"
                color="primary"
                >{{ trans('general.action.save') }}
            </jet-button>
        </template>
    </jet-form-section>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetActionMessage from '@/Jetstream/ActionMessage.vue';

export default {
    components: {
        JetActionMessage,
        JetButton,
        JetFormSection,
        JetInput,
        JetInputError,
        JetLabel,
    },

    data() {
        return {
            form: this.$inertia.form({
                _method: 'PUT',
                name: null,
                email: null,
                photo: null,
            }),

            photoPreview: null,
        };
    },

    computed: {
        isEditable() {
            return this.form.email !== 'superadmin@curie.network';
        },
    },

    mounted() {
        this.form = this.$inertia.form({
            _method: 'PUT',
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            email: this.user.email,
            photo: null,
        });
    },

    methods: {
        updateProfileInformation() {
            if (this.$refs.photo) {
                this.form.photo = this.$refs.photo.files[0];
            }

            this.form.post(route('user-profile-information.update'), {
                errorBag: 'updateProfileInformation',
                preserveScroll: true,
                onSuccess: () => this.clearPhotoFileInput(),
            });
        },

        selectNewPhoto() {
            this.$refs.photo.click();
        },

        updatePhotoPreview() {
            const photo = this.$refs.photo.files[0];

            if (!photo) return;

            const reader = new FileReader();

            reader.onload = (e) => {
                this.photoPreview = e.target.result;
            };

            reader.readAsDataURL(photo);
        },

        deletePhoto() {
            this.$inertia.delete(route('current-user-photo.destroy'), {
                preserveScroll: true,
                onSuccess: () => {
                    this.photoPreview = null;
                    this.clearPhotoFileInput();
                },
            });
        },

        clearPhotoFileInput() {
            if (this.$refs.photo?.value) {
                this.$refs.photo.value = null;
            }
        },
    },
};
</script>
