<template>
    <app-layout>
        <page-header :title="trans('users.overview.title')">
            <div class="d-flex justify-content-between align-items-start mt-3">
                <collapsable-filter-bar
                    :active="activeQueryParams.form.isDirty"
                    :is-processing="activeQueryParams.form.processing"
                    :show-clear-button="false"
                    class="flex-grow-1"
                >
                    <template #visible-filter>
                        <search-filter
                            :is-processing="activeQueryParams.form.processing"
                            :model-value="activeQueryParams.form.search"
                            :placeholder="
                                trans('users.overview.filter.placeholder')
                            "
                            :show-spinner="true"
                            @update:modelValue="updateValue('search', $event)"
                        />
                    </template>
                </collapsable-filter-bar>
                <teleport defer to="#page-header-action">
                    <jet-button
                        v-if="hasAnyPermission('user.create')"
                        type="button"
                        variant="secondary"
                        @click="$inertia.visit(route('users.create'))"
                    >
                        {{ trans('users.overview.action.add_user') }}
                    </jet-button>
                </teleport>
            </div>
        </page-header>
        <data-table
            :actions="actions"
            :columns="fields"
            :items="users"
            :no-items-view="noItemsView"
            :query-params="activeQueryParams"
            action-classes="align-top"
            @row-clicked="showDetails"
        >
            <template #name="{ item }">
                <td class="align-top">
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <div>{{ item.name }}</div>
                        <icon
                            v-if="item.meta?.data.nameIsAmbiguous"
                            v-c-tooltip="{
                                content: 'Ambiguous name',
                            }"
                            class="text-warning"
                            name="cil-warning"
                        />
                    </div>
                    <small
                        v-if="item.lastLoginAt !== undefined"
                        class="text-muted"
                        >{{ trans('users.overview.last_login') }}
                        <span v-if="!!item.lastLoginAt" class="text-nowrap">{{
                            $filters.dateTimeMedium(
                                item.lastLoginAt,
                                currentLocale,
                            )
                        }}</span>
                        <span v-else>{{ trans('general.not_available') }}</span>
                    </small>
                </td>
            </template>

            <template #email="{ item }">
                <td class="align-tops">
                    <email-link :email="item.email" />
                </td>
            </template>

            <template #role="{ item }">
                <td class="align-top">
                    <RoleBadge
                        v-for="role in item.roles"
                        :key="role.id"
                        :role="role"
                    />
                </td>
            </template>

            <template #status="{ item }">
                <td class="align-top">
                    <badge :color="getStatusBadge(item.status)">
                        {{ item.status?.label.toUpperCase() }}
                    </badge>
                </td>
            </template>

            <template #locations="{ item }">
                <td class="align-top text-start">
                    <div v-for="location in item.locations" :key="location.id">
                        {{ location.name }}
                    </div>
                </td>
            </template>
        </data-table>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetSectionTitle from '@/Jetstream/SectionTitle.vue';
import Pagination from '@/Components/Pagination.vue';
import RoleBadge from '@/Components/RoleBadge.vue';
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import EmailLink from '@/Components/EmailLink.vue';
import Card from '@/Components/Card.vue';
import DataTable from '@/Components/DataTable.vue';
import Badge from '@/Components/Badge.vue';
import PageHeader from '@/Components/PageHeader.vue';
import SearchFilter from '@/Pages/WorkOrders/SearchFilter.vue';
import CollapsableFilterBar from '@/Components/CollapsableFilterBar.vue';
import { removeEmptyProperties } from '@/utils.js';
import useQueryParams from '@/Utils/UseQueryParams';
import { isDesktop } from '@/Utils/UseMedia';

export default {
    components: {
        CollapsableFilterBar,
        SearchFilter,
        PageHeader,
        Badge,
        DataTable,
        Card,
        EmailLink,
        AppLayout,
        JetButton,
        JetSectionTitle,
        Pagination,
        RoleBadge,
        CustomCardHeader,
    },

    setup() {
        const activeQueryParams = useQueryParams({
            paramsWithDefaults: {
                search: null,
                sort: { column: 'name', asc: true },
            },
            route: route('users.index'),
            options: {
                only: ['users'],
            },
        });

        return {
            activeQueryParams,
            isDesktop: isDesktop,
        };
    },

    data() {
        return {
            fields: [
                {
                    key: 'name',
                    label: this.trans('users.overview.cell.name'),
                },
                {
                    key: 'email',
                    label: this.trans('users.overview.cell.email'),
                },
                {
                    key: 'role',
                    label: this.trans('users.overview.cell.role'),
                },
                {
                    key: 'status',
                    label: this.trans('users.overview.cell.status'),
                },
                {
                    key: 'locations',
                    label: this.trans('users.overview.cell.locations'),
                },
            ],
            actions: [
                {
                    permissions: ['user.update'],
                    tooltip: this.trans('users.overview.tooltip.edit_user'),
                    type: 'primary',
                    click: this.editUser,
                    icon: 'cil-pencil',
                },
                {
                    permissions: ['user.create'],
                    condition: (item) => item.status?.value === 'invited',
                    tooltip: this.trans(
                        'users.overview.tooltip.resend_invitation',
                    ),
                    type: 'primary',
                    click: this.inviteUser,
                    icon: 'cil-paper-plane',
                },
                {
                    permissions: ['user.revoke'],
                    condition: (item) => item.status?.value !== 'blocked',
                    tooltip: this.trans('users.overview.tooltip.revoke_access'),
                    type: 'primary',
                    click: this.revokeAccess,
                    icon: 'cil-lock-locked',
                },
                {
                    permissions: ['user.revoke'],
                    condition: (item) => item.status?.value === 'blocked',
                    tooltip: this.trans(
                        'users.overview.tooltip.restore_access',
                    ),
                    type: 'primary',
                    click: this.restoreAccess,
                    icon: 'cil-lock-unlocked',
                },
                {
                    permissions: ['user.impersonate'],
                    condition: (item) =>
                        !this.isCurrentUser(item) &&
                        !this.isSuperAdmin(item) &&
                        item.roles?.length > 0,
                    tooltip: this.trans('users.overview.tooltip.impersonate'),
                    type: 'warning',
                    click: this.impersonate,
                    icon: 'cil-user',
                },
                {
                    permissions: ['user.delete'],
                    tooltip: this.trans('users.overview.tooltip.delete_user'),
                    type: 'danger',
                    click: this.deleteUser,
                    icon: 'cil-trash',
                },
            ],
            noItemsView: {
                noItems: this.trans('users.overview.results.empty'),
            },
        };
    },

    computed: {
        users() {
            return this.$page.props.users;
        },
    },

    methods: {
        loadUsers() {
            this.isProcessing = true;

            this.activeQueryParams = removeEmptyProperties({
                ...this.activeFilters.data(),
                sort: this.sortOrder,
            });

            let sortDirection = this.sortOrder.asc ? 'asc' : 'desc';

            this.$inertia.get(
                this.route('users.index', {
                    ...this.activeQueryParams,
                    sort: `${this.sortOrder.column}.${sortDirection}`,
                }),
                {},
                {
                    preserveState: true,
                    onFinish: () => {
                        this.isProcessing = false;
                    },
                },
            );
        },
        showDetails(user) {
            this.$inertia.visit(this.route('users.show', user.id), {
                preserveState: true,
            });
        },
        editUser(user) {
            this.$inertia.visit(this.route('users.edit', user.id), {
                preserveState: true,
            });
        },
        getMailLink(email) {
            return 'mailto:' + email;
        },
        getRoleBadge(role) {
            switch (role.name) {
                case 'super-admin':
                    return 'warning';
                case 'admin':
                    return 'primary';
                default:
                    return 'secondary';
            }
        },
        getStatusBadge(status) {
            switch (status?.value) {
                case 'active':
                    return 'success';
                case 'invited':
                    return 'info';
                default:
                    return 'danger';
            }
        },
        revokeAccess(user) {
            this.$inertia.post(
                this.route('users.revoke_access', user.id),
                {},
                { preserveState: true },
            );
        },
        restoreAccess(user) {
            this.$inertia.get(
                this.route('users.restore_access', user.id),
                {},
                {
                    preserveState: true,
                },
            );
        },
        inviteUser(user) {
            this.$inertia.post(
                this.route('users.invite', user.id),
                {},
                {
                    preserveState: true,
                },
            );
        },
        deleteUser(user) {
            this.$inertia.delete(this.route('users.destroy', user.id), {
                preserveState: true,
            });
        },
        impersonate(user) {
            this.$inertia.get(
                this.route('users.impersonate', user.id),
                {},
                {
                    preserveState: true,
                },
            );
        },
        isCurrentUser(user) {
            return user.id === this.user.id;
        },
        isSuperAdmin(user) {
            return user.roles.map((el) => el.name).includes('super-admin');
        },
        updateValue(key, value) {
            this.activeQueryParams.form[key] = value;
            this.activeQueryParams.change();
        },
    },
};
</script>
