<template>
    <app-layout>
        <page-header :title="trans('test_records.overview.header')">
            <div class="d-flex justify-content-between align-items-start mt-3">
                <test-records-filterbar
                    :is-processing="activeQueryParams.form.processing"
                    :query-params="activeQueryParams"
                    class="w-100"
                />
                <teleport
                    v-if="hasAnyPermission('test.export')"
                    defer
                    to="#page-header-action"
                >
                    <export-menu
                        :active-query-params="activeQueryParams"
                        :no-item-selected-tooltip="
                            trans(
                                'test_records.overview.export.tooltip.no_item_selected',
                            )
                        "
                        :selection="selection"
                        routeName="tests.export"
                    />
                </teleport>
            </div>
        </page-header>
        <data-table
            :columns="fields"
            :items="tests"
            :no-items-view="noItemsView"
            :query-params="activeQueryParams"
            :selection-enabled="selectionEnabled"
            @row-clicked="showDetails"
        >
            <template #date="{ item }">
                <td class="align-top">
                    <div class="text-muted">
                        <div class="text-nowrap">
                            {{ $filters.fullDate(item.date, currentLocale) }}
                        </div>
                        <small>
                            <icon icon="cil-clock" />
                            {{ $filters.time(item.date, currentLocale) }}</small
                        >
                    </div>
                </td>
            </template>

            <template #type="{ item }">
                <td>
                    <div v-if="item.asset">{{ item.asset?.type?.value }}</div>
                </td>
            </template>

            <template #designation="{ item }">
                <td>
                    <div v-if="item.asset">
                        <div>
                            {{ item.asset?.designation.label }}
                        </div>
                        <small class="text-nowrap text-dark"
                            >{{ trans('assets.overview.cell.serial_number') }}:
                            {{ item.asset?.serialNumber || '-' }}</small
                        >
                    </div>
                </td>
            </template>

            <template #location="{ item }">
                <td>
                    <div v-if="item.asset">
                        {{ item.asset?.location?.iataCode }}
                    </div>
                </td>
            </template>

            <template #specific_location="{ item }">
                <specific-location-overview-cell
                    :location="item.asset?.location"
                />
            </template>

            <template #area="{ item }">
                <area-location-overview-cell :location="item.asset?.location" />
            </template>

            <template #user="{ item }">
                <td class="align-top">
                    <user-information v-if="item.user" :info="item.user" />
                    <span v-else>-</span>
                </td>
            </template>

            <template #result="{ item }">
                <td class="align-top text-center">
                    <icon
                        v-if="item.resolution.value === 'success'"
                        v-c-tooltip="{
                            content: trans(
                                'test_records.overview.tooltip.result.successful',
                            ),
                        }"
                        class="text-gray"
                        icon="cil-check-alt"
                        size="lg"
                    />
                    <icon
                        v-else
                        v-c-tooltip="{
                            content: trans(
                                'test_records.overview.tooltip.result.failed',
                            ),
                        }"
                        class="text-danger"
                        icon="cil-warning"
                        size="lg"
                    />
                </td>
            </template>

            <template #report="{ item }">
                <td class="align-top">
                    <div
                        :class="{
                            'text-max-2-lines': !showFullDescription(item),
                        }"
                        class="text-break"
                        style="-webkit-line-clamp: 3"
                    >
                        {{ item.description }}
                    </div>
                </td>
            </template>

            <template #attachments="{ item }">
                <td class="align-top">
                    <attachments-cell
                        :attachments="item.attachments"
                        :can-read="hasAnyPermission(['test.attachment.index'])"
                    />
                </td>
            </template>

            <template #qr_code_scanned="{ item }">
                <td class="align-top text-center">
                    <icon
                        v-if="item.qr_code_scanned_at"
                        v-c-tooltip="{
                            content: trans(
                                'test_records.overview.tooltip.qr_code_scanned',
                            ),
                        }"
                        icon="cil-check-alt"
                        size="lg"
                    />
                    <icon
                        v-else
                        v-c-tooltip="{
                            content: trans(
                                'test_records.overview.tooltip.qr_code_not_scanned',
                            ),
                        }"
                        icon="cil-x"
                        size="lg"
                    />
                </td>
            </template>
        </data-table>
        <attachments-modal />
    </app-layout>
</template>
<script setup>
import PageHeader from '@/Components/PageHeader.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import UserInformation from '@/Components/UserInformation.vue';
import DataTable from '@/Components/DataTable.vue';
import { computed, ref } from 'vue';
import { trans } from '@/mixins/i18n.js';
import { router, usePage } from '@inertiajs/vue3';
import { when } from '@/Utils/when';
import TestRecordsFilterbar from '@/Pages/TestRecords/TestRecordsFilterbar.vue';
import useQueryParams from '@/Utils/UseQueryParams';
import useSelectedItems from '@/Utils/UseSelectedItems';
import ExportMenu from '@/Components/ExportMenu.vue';
import SpecificLocationOverviewCell from '@/Components/SpecificLocationOverviewCell.vue';
import AreaLocationOverviewCell from '@/Components/AreaLocationOverviewCell.vue';
import { hasAnyPermission } from '@/mixins/hasAnyPermission.js';
import AttachmentsCell from '@/Components/AttachmentsCell.vue';
import AttachmentsModal from '@/Components/AttachmentsModal.vue';

const page = usePage();
const testRecordsMeta = computed(() => page.props.tests?.meta || {});
const selection = useSelectedItems('global-test-records', testRecordsMeta);

const activeQueryParams = useQueryParams({
    paramsWithDefaults: {
        search: null,
        start: null,
        end: null,
        types: [],
        locations: [],
        specificLocations: [],
        users: [],
        results: [],
        qr: [],
        sort: { column: 'date', asc: false },
    },
    route: route('tests.index'),
    options: {
        only: ['tests'],
    },
    additionalData: () => ({
        selection: selection.getSelectionAsQueryParams(),
    }),
    onLoadSuccess: (data) => {
        selection.setSelection(data.props.tests.meta.selection);
    },
});

const fields = computed(() =>
    [
        {
            key: 'date',
            label: trans('test_records.overview.cell.date'),
            _style: 'width:120px; min-width: 120px; max-width: 120px',
        },
        {
            key: 'type',
            label: trans('activities.overview.cell.type'),
            _style: 'width: 120px; min-width: 120px; max-width: 120px',
        },
        {
            key: 'designation',
            label: trans('activities.overview.cell.designation'),
            _style: 'width: 150px; min-width: 150px; max-width: 150px',
        },
        // Show column if there are multiple airports
        when(locations.value.data.length > 1, {
            key: 'location',
            label: trans('activities.overview.cell.location'),
            _style: 'width: 130px; min-width: 130px; max-width: 130px',
        }),
        {
            key: 'specific_location',
            label: trans('activities.overview.cell.specific_location'),
            _style: 'width: 15%',
        },
        when(tests.value?.meta.hasAreaLocation, {
            key: 'area',
            label: trans('locations.area.label'),
            _style: 'width: 15%',
        }),
        {
            key: 'qr_code_scanned',
            label: trans('test_records.overview.cell.qr_code_scanned'),
            _style: 'width: 70px; min-width: 70px; max-width: 70px',
        },
        {
            key: 'result',
            label: trans('test_records.overview.cell.result'),
            _style: 'width: 95px; min-width: 95px; max-width: 95px',
        },
        {
            key: 'report',
            label: trans('test_records.overview.cell.report'),
            _style: 'min-width: 350px',
        },
        {
            key: 'user',
            label: trans('test_records.overview.cell.user'),
            _style: 'width: 200px; min-width: 200px; max-width: 200px',
        },
        {
            key: 'attachments',
            label: trans('test_records.overview.cell.attachments'),
        },
    ].filter(Boolean),
);

const noItemsView = ref({
    noItems: trans('test_records.overview.results.empty'),
});

const selectionEnabled = computed(() => {
    return hasAnyPermission('test.export') ? 'global-test-records' : false;
});

const openReports = ref([]);
const tests = computed(() => page.props.tests);
const locations = computed(() => page.props.locations);

const showFullDescription = (item) => openReports.value.includes(item.id);
const showDetails = function (item) {
    window.open(route('tests.show', item.id), '_blank').focus();
};

const loadTestRecords = () => {
    isProcessing.value = true;

    router.get(route('tests.index'), activeQueryParams.urlParams(), {
        only: ['tests'],
        preserveState: true,
        preserveScroll: true,
        onFinish: () => {
            isProcessing.value = false;
        },
    });
};
</script>
