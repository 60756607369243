<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-2">
            <h3 class="mb-0">{{ trans('operators.overview.title') }}</h3>
            <div class="d-flex align-items-end center gap-3">
                <small class="text-muted">{{
                    trans('operators.overview.results.count', {
                        total: operators.data.length,
                    })
                }}</small>
                <jet-button
                    v-if="!isTablet && hasAnyPermission('operator.create')"
                    type="button"
                    variant="secondary"
                    @click.stop="createOperator"
                    >{{ trans('operators.overview.action.add') }}
                </jet-button>
            </div>
        </div>

        <div v-if="operators.data.length === 0">
            <card style="padding: 0 !important" type="decent">
                <div class="text-center text-gray">
                    <div class="text-semi-bold">
                        {{
                            trans('stakeholders.overview.empty.operators.title')
                        }}
                    </div>
                    <div>
                        {{ trans('stakeholders.overview.empty.description') }}
                    </div>
                </div>
            </card>
        </div>
        <div v-else>
            <card
                v-for="operator in operators.data"
                :key="operator.id"
                card-classes="mb-2"
                style="padding: 0 !important"
                type="decent"
            >
                <header
                    class="d-flex justify-content-between align-items-center"
                >
                    <h4 class="h6">{{ operator.name }}</h4>
                    <div
                        v-if="
                            !isTablet &&
                            hasAnyPermission([
                                'operator.update',
                                'operator.delete',
                            ])
                        "
                        class="d-flex gap-1"
                    >
                        <jet-button
                            v-if="hasAnyPermission('operator.update')"
                            v-c-tooltip="{
                                content: trans('general.action.edit'),
                            }"
                            class="btn-icon"
                            type="button"
                            variant="secondary"
                            @click="editOperator(operator)"
                        >
                            <icon name="cil-pencil" />
                        </jet-button>
                        <jet-button
                            v-if="hasAnyPermission('operator.delete')"
                            v-c-tooltip="{
                                content: trans('general.action.delete'),
                            }"
                            :destructive="true"
                            class="btn-icon"
                            type="button"
                            variant="secondary"
                            @click="deleteOperator(operator)"
                        >
                            <icon name="cil-trash" />
                        </jet-button>
                    </div>
                </header>
                <section>
                    <row>
                        <column lg="2" md="3">
                            <div class="text-nowrap">
                                {{
                                    trans(
                                        'stakeholders.overview.assets_count.label',
                                        {
                                            count: operator.assetsCount || 0,
                                        },
                                    )
                                }}
                            </div>
                            <div class="text-nowrap">
                                {{
                                    trans(
                                        'stakeholders.overview.locations_count.label',
                                        {
                                            count:
                                                operator.topLevelLocationsCount ||
                                                0,
                                        },
                                    )
                                }}
                            </div>
                        </column>
                        <column>
                            <div v-if="operator.email || operator.description">
                                <div v-if="operator.email">
                                    <email-link
                                        :email="operator.email"
                                        :show-icon="true"
                                    />
                                </div>
                                <div
                                    v-if="operator.description"
                                    class="d-flex align-items-top gap-1"
                                >
                                    <icon
                                        name="cil-briefcase"
                                        style="position: relative; top: 1px"
                                    />
                                    <span>{{ operator.description }}</span>
                                </div>
                            </div>
                            <div v-else>
                                {{ trans('general.not_available') }}
                            </div>
                        </column>
                    </row>
                </section>
            </card>
        </div>
        <update-or-create-operator-form-modal />
    </div>
</template>
<script>
import JetButton from '@/Jetstream/Button.vue';
import Pagination from '@/Components/Pagination.vue';
import TotalTableItems from '@/Components/TotalTableItems.vue';
import EmailLink from '@/Components/EmailLink.vue';
import { eventBus, events } from '@/eventBus.js';
import DeleteStakeholderModal from '@/Pages/Stakeholders/DeleteStakeholderModal.vue';
import Card from '@/Components/Card.vue';
import UpdateOrCreateOperatorFormModal from '@/Pages/Stakeholders/Operators/UpdateOrCreateOperatorFormModal.vue';
import Row from '@/Components/Row.vue';
import { isDesktop, isTablet } from '@/Utils/UseMedia';
import { trans } from '@/mixins/i18n.js';
import { hasAnyPermission } from '@/mixins/hasAnyPermission.js';

export default {
    name: 'OperatorsOverview',
    components: {
        Row,
        UpdateOrCreateOperatorFormModal,
        Card,
        DeleteStakeholderModal,
        EmailLink,
        TotalTableItems,
        Pagination,
        JetButton,
    },
    computed: {
        operators() {
            return this.$page.props.operators;
        },
    },
    setup() {
        return {
            isDesktop,
            isTablet,
        };
    },
    methods: {
        hasAnyPermission,
        trans,
        createOperator() {
            eventBus.$emit(events.openCreateOperatorModal);
        },
        editOperator(item) {
            eventBus.$emit(events.openEditOperatorModal, { operator: item });
        },
        deleteOperator(item) {
            eventBus.$emit(events.openConfirmDeleteStakeholderModal, {
                stakeholder: item,
                type: 'operator',
            });
        },
    },
};
</script>
