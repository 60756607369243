<template>
    <div class="d-flex align-items-center" style="gap: 10px">
        <em
            class="operability-indicator flex-shrink-0"
            :class="[type, size]"
            style="border-color: #525252"
        />
        <slot />
    </div>
</template>

<script>
export default {
    name: 'OperabilityIndicator',
    props: {
        type: {
            type: String,
            validator: (value) => ['operable', 'inoperable'].includes(value),
        },
        size: {
            type: String,
            validator: (value) => ['sm', 'md'].includes(value),
            default: 'md',
        },
    },
};
</script>
