<template>
    <dropdown
        v-if="languages.length > 1"
        :caret="true"
        toggler-classes="ps-0 pe-5 arrow-gray"
        variant="nav-item"
    >
        <template #toggler>
            <span>{{ currentLanguageLabel }}</span>
        </template>
        <dropdown-item
            v-for="lang in languages"
            :key="lang.value"
            :href="$route('language', lang.value)"
            @click="changeLanguage(lang.value)"
            v-text="lang.label"
        />
    </dropdown>
</template>
<script>
import { getLabelForLocale } from '@/Utils/getLabelForLocale';
import Dropdown from '@/Components/Dropdown.vue';
import DropdownItem from '@/Components/DropdownItem.vue';
import ArrowDown from '../../icons/ArrowDown.vue';

export default {
    name: 'LanguageSelector',
    components: { ArrowDown, DropdownItem, Dropdown },
    props: {
        useFullLabel: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        current() {
            return this.currentLocale;
        },
        currentLanguageLabel() {
            return this.useFullLabel
                ? this.getLabelForLocale(this.current)
                : this.current.toUpperCase();
        },
        languages() {
            return (
                this.$page.props.supported_locales?.map((locale) => ({
                    value: locale,
                    label: getLabelForLocale(locale),
                })) || []
            );
        },
    },
    methods: {
        getLabelForLocale(locale) {
            return (
                this.languages.find((el) => el.value === locale).label || locale
            );
        },
        changeLanguage(locale) {
            this.$inertia.visit(this.route('language', locale), {
                replace: true,
                onSuccess: () => {
                    document.dir = locale === 'ar' ? 'rtl' : 'ltr';
                },
            });
        },
    },
};
</script>
<style scoped>
:deep(.dropdown-menu) {
    min-width: auto;
    overflow: hidden;
}

:deep(.dropdown-item) {
    min-width: auto !important;
}
</style>
