<template>
    <app-header>
        <div class="d-flex gap-3 align-items-center">
            <toggler
                class="d-lg-none p-2 d-inline-flex justify-content-center"
                style="width: 44px; height: 44px"
                @click="sidebar.toggleVisible()"
            />
        </div>
        <div class="d-flex gap-4 align-items-center">
            <header-nav>
                <nav-item
                    v-if="showTranslationKeyToggle"
                    class="clickable"
                    style="color: white"
                    title="Toggle translation keys"
                    @click.stop="toggleKeys"
                >
                    <icon name="cil-language" />
                </nav-item>
                <nav-item>
                    <notification-center />
                </nav-item>
                <nav-item>
                    <the-header-dropdown-account />
                </nav-item>
                <nav-item>
                    <button
                        class="btn btn-primary d-inline-flex scan-btn btn-extended"
                        style="gap: 5px; min-width: auto; min-height: 2rem"
                        type="button"
                        @click.prevent="openQrScanner"
                    >
                        <icon
                            name="cil-qr-code"
                            style="shape-rendering: crispEdges"
                        />
                        {{ trans('general.action.scan') }}
                    </button>
                </nav-item>
            </header-nav>
            <div v-if="logoUrl" class="text-white">
                <LogoCurie
                    :height="28"
                    :width="114"
                    title="Curie Technologies Inc."
                />
            </div>
        </div>
    </app-header>
</template>

<script setup>
import NotificationCenter from '@/Components/NotificationCenter/NotificationCenter.vue';
import HeaderNav from '@/Components/HeaderNav.vue';
import NavItem from '@/Components/NavItem.vue';
import TheHeaderDropdownAccount from '@/Containers/HeaderDropdownAccount.vue';
import AppHeader from '@/Components/AppHeader.vue';
import { useSidebarStore } from '@/stores/sidebar.js';
import Toggler from '@/Components/Toggler.vue';
import { eventBus, events } from '@/eventBus.js';
import { showTranslationsKeys } from '@/mixins/i18n.js';
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';
import LogoCurie from '../../icons/LogoCurie.vue';

const page = usePage();
const sidebar = useSidebarStore();
const openQrScanner = () => {
    eventBus.$emit(events.openQrCodeScanner);
};

const showTranslationKeyToggle = computed(() => {
    return page.props.enableTranslationKeySwitch;
});

const toggleKeys = () => {
    showTranslationsKeys.value = !showTranslationsKeys.value;
};
const logoUrl = computed(() => page.props.settings.big_logo_url);
</script>
<style scoped>
.scan-btn {
    padding: 0.25rem 0.5rem;
}
.scan-btn:hover,
.scan-btn:focus {
    background: var(--c-btn-bg) !important;
}

:deep(.dropdown-toggle) {
    padding: 0 5px !important;
}
</style>
