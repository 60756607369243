<template>
    <button :class="classes" :disabled="isProcessing || disabled" :type="type">
        <icon
            v-if="icon"
            :icon="icon"
            :style="{ visibility: isProcessing ? 'hidden' : 'visible' }"
            size="sm"
        />
        <span
            v-else
            :style="{ visibility: isProcessing ? 'hidden' : 'visible' }"
            class="d-flex align-items-center text-truncate d-block gap-1"
        >
            <slot />
        </span>
        <span
            v-if="isProcessing"
            class="d-flex position-absolute d-flex justify-content-center align-items-center"
            style="top: 0; right: 0; bottom: 0; left: 0"
        >
            <spinner :color="spinnerColor" />
        </span>
    </button>
</template>

<script>
import { CButton } from '@coreui/vue/dist/esm/components/button/index.js';

export default {
    name: 'JetButton',
    components: { CButton },

    props: {
        type: {
            type: String,
            default: 'submit',
        },
        isProcessing: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        destructive: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            validator(value, props) {
                return ['primary', 'secondary', 'tertiary'].includes(value);
            },
            default: 'primary',
        },
        icon: {
            type: String,
        },
    },

    computed: {
        classes() {
            return [
                'btn',
                'text-nowrap',
                `btn-${this.variant}`,
                this.disabled ? 'disabled' : '',
                this.destructive ? 'destructive' : '',
                this.isProcessing ? 'processing' : '',
                this.icon ? 'btn-icon' : '',
            ];
        },
        spinnerColor() {
            if (this.color === 'warning') {
                return '';
            }

            if (!this.variant) {
                return 'light';
            }

            if (this.shape === 'pill') {
                if (this.color === 'danger') {
                    return 'danger';
                }
                return 'primary';
            }
            return 'light';
        },
    },
};
</script>
<style>
.spinner {
    position: absolute;
}
</style>
