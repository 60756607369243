<template>
    <form-group
        :group-classes="formGroupClasses"
        :horizontal="horizontal"
        :label="label"
        :label-classes="{ required }"
        class="datepicker"
    >
        <div v-if="!disabled">
            <date-picker
                ref="datepicker"
                :locale="currentLocale"
                :max-date="maxDate"
                :min-date="minDate"
                :model-value="modelValue"
                :popover="{ visibility: 'focus' }"
                @dayclick="dateChanged"
            >
                <template #default="{ inputValue, inputEvents }">
                    <slot :inputEvents="inputEvents" :inputValue="inputValue">
                        <div class="input-group">
                            <div
                                :class="[
                                    `bg-${color}`,
                                    `border-${color}`,
                                    { 'text-white': color !== 'tertiary' },
                                ]"
                                class="input-group-text"
                                @click.stop="focus"
                            >
                                <icon icon="cil-calendar" />
                            </div>
                            <input
                                :id="id"
                                ref="input"
                                :class="{ 'is-invalid': !isValid }"
                                :placeholder="placeholder"
                                :required="required"
                                :value="formattedDate"
                                class="form-control position-relative"
                                readonly
                                v-on="inputEvents"
                            />
                            <div
                                v-if="deletable"
                                v-c-tooltip="{
                                    content: trans('general.action.clear'),
                                    popperOptions: { positionFixed: true },
                                }"
                                :class="{
                                    'd-none': !modelValue,
                                }"
                                class="input-group-text text-danger p-0"
                            >
                                <button
                                    class="btn btn-icon text-danger"
                                    type="button"
                                    @click="$emit('update:modelValue', null)"
                                >
                                    <icon icon="cil-x" />
                                </button>
                            </div>
                        </div>
                    </slot>
                    <div v-if="description" class="text-muted">
                        <small>{{ description }}</small>
                    </div>
                </template>
            </date-picker>
            <div
                v-if="invalidFeedback"
                class="invalid-feedback"
                style="display: block"
            >
                {{ invalidFeedback }}
            </div>
        </div>
        <div v-else>
            <div class="input-group">
                <span
                    :class="[
                        `bg-${color}`,
                        `border-${color}`,
                        { 'text-white': color !== 'tertiary' },
                    ]"
                    class="input-group-text btn disabled"
                    style="min-width: 0"
                >
                    <icon icon="cil-calendar" />
                </span>
                <input class="form-control" disabled />
            </div>
        </div>
    </form-group>
</template>

<script>
import { DatePicker } from 'v-calendar';
import { DateTime } from 'luxon';

import FormGroup from '@/Components/FormGroup.vue';
import { trans } from '@/mixins/i18n';

export default {
    name: 'JetDatepicker',

    components: {
        FormGroup,
        DatePicker,
    },

    props: {
        label: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'text',
        },
        invalidFeedback: {
            type: String,
            default: '',
        },
        modelValue: [Date, Object, String, null],
        required: {
            type: Boolean,
            default: false,
        },
        horizontal: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: () => trans('general.form.select.placeholder'),
        },
        description: {
            type: String,
            default: '',
        },
        minDate: {
            type: [Object, String],
        },
        maxDate: {
            type: [Object, String],
        },
        deletable: {
            type: Boolean,
            default: true,
        },
        color: {
            type: String,
            default: 'primary',
        },
        formGroupClasses: {
            type: [String, Object, Array],
        },
    },

    data() {
        return {
            id: null,
        };
    },

    emits: ['update:modelValue'],

    computed: {
        isValid() {
            return (
                this.invalidFeedback === null ||
                this.invalidFeedback?.trim() === ''
            );
        },
        formattedDate() {
            if (!this.modelValue || this.modelValue.isValid === false) {
                return '';
            }
            return this.$filters.fullDate(this.modelValue, this.currentLocale);
        },
    },

    mounted() {
        this.id = this._uid;
    },

    methods: {
        focus() {
            this.$refs.input.focus();
        },
        dateChanged(value, event) {
            event.target.blur();

            let date = DateTime.fromJSDate(value.date);

            date = date.isValid
                ? date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                : null;

            const dateToCompare =
                typeof this.minDate === 'string'
                    ? DateTime.fromISO(this.minDate)
                    : this.minDate;

            if (dateToCompare > date) {
                return;
            }

            this.$emit('update:modelValue', date);
        },
    },
};
</script>
<style scoped>
.form-control[readonly] {
    background: none;
}

:deep(.vc-day.is-today .vc-day-content) {
    background: #efefef;
}

:deep(.vc-day.is-today .vc-highlights + .vc-day-content) {
    background: none;
}

:deep(.vc-highlight) {
    background-color: #2c54ea !important;
}
</style>
