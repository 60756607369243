<template>
    <app-layout>
        <asset-details-header />
        <div class="py-6">
            <card>
                <template #header><h5 class="mb-0">Performance</h5></template>
                <performance-data-table />
            </card>
        </div>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import AssetDetailsHeader from '@/Pages/Assets/Details/AssetDetailsHeader.vue';
import PerformanceDataTable from '@/Pages/Assets/Details/PerformanceData/PerformanceDataTable.vue';
import Pagination from '@/Components/Pagination.vue';
import Card from '@/Components/Card.vue';

export default {
    components: {
        Card,
        Pagination,
        PerformanceDataTable,
        AppLayout,
        AssetDetailsHeader,
    },

    computed: {
        asset() {
            return this.$page.props.asset;
        },
        performanceData() {
            return this.$page.props.performanceData;
        },
    },
};
</script>
