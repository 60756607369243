<template>
    <app-layout>
        <page-header :title="trans('activities.overview.headline')">
            <div class="d-flex justify-content-between align-items-start mt-3">
                <activity-filterbar
                    :query-params="activeQueryParams"
                    class="w-100"
                />
                <teleport
                    v-if="hasAnyPermission('activity.export')"
                    defer
                    to="#page-header-action"
                >
                    <export-menu
                        :active-query-params="activeQueryParams"
                        :allowed-exports="['csv', 'xlsx']"
                        :no-item-selected-tooltip="
                            trans(
                                'activities.overview.export.tooltip.no_item_selected',
                            )
                        "
                        :selection="selection"
                        routeName="activities.export"
                    />
                </teleport>
            </div>
        </page-header>
        <data-table
            :columns="fields"
            :items="activities"
            :no-items-view="noItemsView"
            :query-params="activeQueryParams"
            :row-clickable="false"
            selection-enabled="global-activities"
        >
            <template #date="{ item }">
                <td class="align-top">
                    <div class="text-muted">
                        <div class="text-nowrap">
                            {{ $filters.fullDate(item.date, currentLocale) }}
                        </div>
                        <small>
                            <icon icon="cil-clock" />
                            {{ $filters.time(item.date, currentLocale) }}</small
                        >
                    </div>
                </td>
            </template>

            <template #type="{ item }">
                <td>
                    <div v-if="item.asset">{{ item.asset?.type?.value }}</div>
                </td>
            </template>

            <template #designation="{ item }">
                <td>
                    <div v-if="item.asset">
                        <div>
                            {{ item.asset?.designation.label }}
                        </div>
                        <small class="text-nowrap text-dark"
                            >{{ trans('assets.overview.cell.serial_number') }}:
                            {{ item.asset?.serialNumber }}</small
                        >
                    </div>
                </td>
            </template>

            <template #location="{ item }">
                <td>
                    <div v-if="item.asset">
                        {{ item.asset?.location.iataCode }}
                    </div>
                </td>
            </template>

            <template #specific_location="{ item }">
                <specific-location-overview-cell
                    :location="item.asset?.location"
                />
            </template>

            <template #area="{ item }">
                <area-location-overview-cell :location="item.asset?.location" />
            </template>

            <template #causer="{ item, style }">
                <td :style="style" class="align-top">
                    <user-information v-if="item.causer" :info="item.causer" />
                    <span v-else>-</span>
                </td>
            </template>

            <template #event="{ item, style }">
                <td :style="style" class="align-top">
                    <activity :item="item" />
                </td>
            </template>
        </data-table>
    </app-layout>
</template>
<script setup>
import PageHeader from '@/Components/PageHeader.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import UserInformation from '@/Components/UserInformation.vue';
import DataTable from '@/Components/DataTable.vue';
import Activity from '@/Components/ActivityLog/Activity.vue';
import { computed, ref } from 'vue';
import { trans } from '@/mixins/i18n.js';
import { usePage } from '@inertiajs/vue3';
import ActivityFilterbar from '@/Pages/Activities/ActivityFilterbar.vue';
import { when } from '@/Utils/when';
import useQueryParams from '@/Utils/UseQueryParams';
import useSelectedItems from '@/Utils/UseSelectedItems';
import ExportMenu from '@/Components/ExportMenu.vue';
import SpecificLocationOverviewCell from '@/Components/SpecificLocationOverviewCell.vue';
import AreaLocationOverviewCell from '@/Components/AreaLocationOverviewCell.vue';
import { hasAnyPermission } from '@/mixins/hasAnyPermission.js';

const page = usePage();
const activitiesMeta = computed(() => page.props.activities?.meta);
const selection = useSelectedItems('global-activities', activitiesMeta);

const activeQueryParams = useQueryParams({
    paramsWithDefaults: {
        search: null,
        start: null,
        end: null,
        users: [],
        types: [],
        locations: [],
        specificLocations: [],
        sort: { column: 'date', asc: false },
    },
    additionalData: () => ({
        selection: selection.getSelectionAsQueryParams(),
    }),
    onLoadSuccess: (data) => {
        selection.setSelection(data.props.activities.meta.selection);
    },
    route: route('activities.index'),
    options: {
        only: ['activities'],
    },
});

const fields = computed(() =>
    [
        {
            key: 'date',
            label: trans('activities.overview.cell.date'),
            _style: 'width: 120px; min-width: 120px; max-width: 120px',
        },
        {
            key: 'causer',
            label: trans('activities.overview.cell.user'),
            _style: 'width: 200px; min-width: 200px; max-width: 200px',
        },
        {
            key: 'event',
            label: trans('activities.overview.cell.event'),
            sorter: false,
            _style: 'width: 350px; min-width:350px; max-width: 350px',
        },
        {
            key: 'type',
            label: trans('activities.overview.cell.type'),
            _style: 'width: 90px;min-width: 90px; max-width: 90px',
        },
        {
            key: 'designation',
            label: trans('activities.overview.cell.designation'),
            _style: 'width: 150px; min-width: 150px; max-width: 150px',
        },
        // Show column if there are multiple airports
        when(locations.value.data.length > 1, {
            key: 'location',
            label: trans('activities.overview.cell.location'),
            _style: 'width: 130px; min-width: 130px; max-width:130px',
        }),
        {
            key: 'specific_location',
            label: trans('activities.overview.cell.specific_location'),
        },
        when(activities.value?.meta.hasAreaLocation, {
            key: 'area',
            label: trans('locations.area.label'),
        }),
    ].filter(Boolean),
);

const noItemsView = ref({
    noItems: trans('activities.overview.results.empty'),
});

const activities = computed(() => page.props.activities);
const locations = computed(() => page.props.locations);
</script>
