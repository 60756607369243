<template>
    <div>
        <asset-status-header
            :active-filters="activeFilters"
            :is-processing="loadingAssetStatus"
            @filter="handleFilterChange"
        />
        <div
            class="d-sm-flex justify-content-center justify-content-sm-start align-items-start"
            style="gap: 40px"
        >
            <div class="d-flex justify-content-center">
                <chart
                    :data="{
                        datasets: [
                            {
                                data: [
                                    operableAssets || 0,
                                    inoperableAssets || 0,
                                ],
                                backgroundColor: ['#00A37B', '#CC0030'],
                                borderWidth: 0,
                            },
                        ],
                    }"
                    :options="{
                        cutoutPercentage: 45,
                        maintainAspectRatio: false,
                        tooltips: { enabled: false },
                        legend: { display: false },
                        animation: { animateRotate: false },
                        layout: { padding: 0 },
                    }"
                    class="mt-2"
                    style="
                        height: 160px;
                        width: 160px;
                        max-width: 160px;
                        position: relative;
                    "
                    type="doughnut"
                />
            </div>
            <div
                class="d-flex flex-column flex-md-row align-items-start mt-4"
                style="gap: 40px"
            >
                <div>
                    <div
                        class="d-flex justify-content-start align-items-start"
                        style="gap: 10px"
                    >
                        <operability-indicator type="operable">
                            <div style="font-size: 40px; line-height: 0.8">
                                {{ operableAssets || '-' }}
                            </div>
                        </operability-indicator>
                        <div class="text-muted" style="line-height: 1">
                            <div>{{ operablePercentageOfAssets }}%</div>
                            <small>{{
                                trans(
                                    'dashboard.asset_management.of_your_selected_assets',
                                )
                            }}</small>
                        </div>
                    </div>
                    <div class="mt-1">
                        {{
                            trans(
                                'dashboard.asset_management.status.operable_assets',
                            )
                        }}
                    </div>
                </div>
                <div>
                    <div
                        class="d-flex justify-content-start align-items-start"
                        style="gap: 10px"
                    >
                        <operability-indicator type="inoperable">
                            <div style="font-size: 40px; line-height: 0.8">
                                {{ inoperableAssets || '-' }}
                            </div>
                        </operability-indicator>
                        <div class="text-muted" style="line-height: 1">
                            <div>{{ inoperablePercentageOfAssets }}%</div>
                            <small>{{
                                trans(
                                    'dashboard.asset_management.of_your_selected_assets',
                                )
                            }}</small>
                        </div>
                    </div>
                    <div class="mt-1">
                        {{
                            trans(
                                'dashboard.asset_management.status.inoperable_assets',
                            )
                        }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AssetStatusHeader from '@/Pages/Dashboard/Components/AssetStatus/Header.vue';
import OperabilityIndicator from '@/Components/OperabilityIndicator.vue';
import Chart from '@/Components/Chart.vue';

export default {
    name: 'AssetStatus',
    components: { Chart, OperabilityIndicator, AssetStatusHeader },
    props: {
        operableAssets: {
            type: Number,
            default: 0,
        },
        inoperableAssets: {
            type: Number,
            default: 0,
        },
        activeFilters: {
            type: Array,
            default: () => ['active'],
        },
        isProcessing: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loadingAssetStatus: null,
        };
    },
    watch: {
        isProcessing: function (value) {
            this.loadingAssetStatus = !value ? null : this.loadingAssetStatus;
        },
    },
    computed: {
        totalSelectedAssets() {
            let operable =
                typeof this.operableAssets === 'number'
                    ? this.operableAssets
                    : 0;
            let inoperable =
                typeof this.inoperableAssets === 'number'
                    ? this.inoperableAssets
                    : 0;
            return operable + inoperable;
        },
        operablePercentageOfAssets() {
            if (this.totalSelectedAssets === 0) {
                return 0;
            }

            let operable = this.operableAssets || 0;

            return Math.round((operable / this.totalSelectedAssets) * 100) || 0;
        },
        inoperablePercentageOfAssets() {
            if (this.totalSelectedAssets === 0) {
                return 0;
            }
            return 100 - this.operablePercentageOfAssets;
        },
    },
    methods: {
        handleFilterChange(filter) {
            this.loadingAssetStatus = filter;
            this.$emit('filter', filter);
        },
    },
};
</script>
