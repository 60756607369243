<template>
    <teleport :to="domTarget" defer>
        <div :class="cssClasses" style="gap: 10px">
            <jet-button
                type="button"
                variant="tertiary"
                @click.prevent="cancel"
            >
                <span v-if="hasChanges">{{
                    trans('general.discard_changes.modal.title')
                }}</span>
                <span v-else>{{ trans('general.action.cancel') }}</span>
            </jet-button>
            <jet-button
                :disabled="disabled"
                :is-processing="isProcessing"
                color="primary"
                @click.stop="$emit('submit')"
            >
                {{ buttonLabel }}
            </jet-button>
        </div>
    </teleport>
    <discard-changes-modal />
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';
import { eventBus, events } from '@/eventBus.js';
import DiscardChangesModal from '@/Pages/Locations/DiscardChangesModal.vue';
import { isDesktop, isMobile } from '@/Utils/UseMedia';

export default {
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        isUpdateForm: {
            type: Boolean,
            default: false,
        },
        hasChanges: Boolean,
        cancelRoute: String,
        isProcessing: {
            type: Boolean,
            default: false,
        },
        primaryActionLabel: String,
    },
    emits: ['submit'],
    components: {
        DiscardChangesModal,
        JetButton,
    },
    setup() {
        return { isDesktop, isMobile };
    },
    computed: {
        domTarget() {
            // Desktop will show the buttons in the header section
            // Mobile will show the buttons in the bottom sheet
            return this.isMobile
                ? '#sticky-bottom-sheet'
                : '#page-header-action';
        },
        cssClasses() {
            return this.isMobile
                ? 'd-flex flex-column flex-column-reverse'
                : 'd-flex';
        },
        buttonLabel() {
            if (this.primaryActionLabel) {
                return this.primaryActionLabel;
            }

            return this.isUpdateForm
                ? this.trans('general.action.save')
                : this.trans('general.action.create');
        },
    },
    methods: {
        cancel() {
            if (this.hasChanges) {
                eventBus.$emit(events.openConfirmDiscardChangesModal, {
                    onConfirm: () => {
                        setTimeout(() => {
                            this.$inertia.visit(this.cancelRoute, {
                                data: {
                                    force: true,
                                },
                            });
                        }, 150);
                    },
                });

                return;
            }

            this.$inertia.visit(this.cancelRoute);
        },
    },
};
</script>
