<template>
    <page-header :title="asset.name" header-classes="pb-0">
        <teleport defer to="#page-header-action">
            <row>
                <column class="d-md-flex" style="gap: 8px">
                    <tooltip-wrapper
                        v-if="
                            hasFeature('asset-status-check') &&
                            hasAnyPermission('asset.status_check.create')
                        "
                        :enabled="isOperationInProgress || !isAssetOnboarded"
                        :tooltip="
                            trans(
                                'asset.action_disabled_due_to_incomplete_onboarding',
                            )
                        "
                    >
                        <jet-button
                            :disabled="
                                isOperationInProgress || !isAssetOnboarded
                            "
                            class="text-nowrap sm-full-width mb-3"
                            variant="secondary"
                            @click="openCheckAssetStatus"
                            >{{
                                trans(
                                    'assets.details.action.check_asset_status',
                                )
                            }}
                        </jet-button>
                    </tooltip-wrapper>
                    <tooltip-wrapper
                        v-if="
                            hasFeature('test-record') &&
                            hasAnyPermission('test.create')
                        "
                        :enabled="isOperationInProgress || !isAssetOnboarded"
                        :tooltip="
                            trans(
                                'asset.action_disabled_due_to_incomplete_onboarding',
                            )
                        "
                    >
                        <jet-button
                            id="btn-record-test"
                            :disabled="
                                isOperationInProgress || !isAssetOnboarded
                            "
                            class="text-nowrap sm-full-width mb-3"
                            variant="secondary"
                            @click="openTestRecordModal"
                            >{{
                                trans('assets.details.action.calibrate_asset')
                            }}
                        </jet-button>
                    </tooltip-wrapper>
                    <tooltip-wrapper
                        v-if="
                            hasFeature('asset-work-order-management') &&
                            hasAnyPermission('workorder.create')
                        "
                        :enabled="isOperationInProgress || !isAssetOnboarded"
                        :tooltip="
                            trans(
                                'asset.action_disabled_due_to_incomplete_onboarding',
                            )
                        "
                    >
                        <jet-button
                            :disabled="
                                isOperationInProgress || !isAssetOnboarded
                            "
                            class="text-nowrap sm-full-width mb-3"
                            variant="secondary"
                            @click="openWorkOrderModal"
                        >
                            {{ trans('workorder.overview.action.create') }}
                        </jet-button>
                    </tooltip-wrapper>
                    <tooltip-wrapper
                        v-if="
                            hasFeature('performance-data') &&
                            hasAnyPermission('performance_data.create')
                        "
                        :enabled="!isAssetOnboarded"
                        :tooltip="
                            trans(
                                'asset.action_disabled_due_to_incomplete_onboarding',
                            )
                        "
                    >
                        <jet-button
                            :disabled="
                                isOperationInProgress || !isAssetOnboarded
                            "
                            class="text-nowrap sm-full-width mb-3"
                            type="button"
                            variant="secondary"
                            @click="openPerformanceDataModal"
                        >
                            Capture Performance
                        </jet-button>
                    </tooltip-wrapper>
                </column>
            </row>
        </teleport>
        <ul class="nav nav-tabs">
            <li v-for="tab in tabs" :key="tab.route" class="nav-item">
                <a
                    :class="{
                        active: isActiveRoute(tab.route),
                    }"
                    :href="$route(tab.route, asset)"
                    class="nav-link"
                    target="_self"
                    @click.prevent="openTab(tab.route)"
                >
                    <div class="d-flex align-items-center" style="gap: 5px">
                        <span class="text-truncate d-block">{{
                            tab.label
                        }}</span>
                        <exclamation-mark v-if="tab.badge" type="filled" />
                    </div>
                </a>
            </li>
        </ul>
    </page-header>
    <create-test-record-modal :asset="asset" @created="onTestRecordCreated" />
    <check-asset-status-form
        :asset-id="asset.id"
        @performed="$emit('status-check-performed')"
    />
    <create-work-order-modal
        :forced-work-order-type="forcedWorkOrderType"
        @created="$emit('workorder-created')"
    />
    <confirm-corrective-maintenance-creation-modal />
    <create-performance-data-modal />
    <asset-status-change-prompt-modal />
</template>
<script>
import CalibrationRecordForm from '@/Pages/Assets/Modals/CreateTestRecordModal.vue';
import CreateTestRecordModal from '@/Pages/Assets/Modals/CreateTestRecordModal.vue';
import JetButton from '@/Jetstream/Button.vue';
import CheckAssetStatusForm from '@/Components/AssetStatusCheckModal.vue';
import TooltipWrapper from '@/Pages/Assets/Details/TooltipWrapper.vue';
import CreateWorkOrderModal from '@/Pages/WorkOrders/Modals/CreateWorkOrderModal.vue';
import Wrench from '@/../icons/Wrench.vue';
import CreateCorrectiveMaintenanceModal from '@/Pages/Assets/Modals/ConfirmationStepModal.vue';
import { eventBus, events } from '@/eventBus';
import CreatePerformanceDataModal from '@/Pages/Assets/Details/PerformanceData/CreatePerformanceDataModal.vue';

import Badge from '@/Components/Badge.vue';
import ConfirmCorrectiveMaintenanceCreationModal from '@/Pages/Assets/Details/ConfirmCorrectiveMaintenanceCreationModal.vue';
import PageHeader from '@/Components/PageHeader.vue';
import ExclamationMark from '../../../../icons/ExclamationMark.vue';
import AssetStatusChangePromptModal from '@/Pages/WorkOrders/Modals/AssetStatusChangePromptModal.vue';

export default {
    props: ['activeTab'],

    emits: ['status-check-performed', 'workorder-created'],

    components: {
        AssetStatusChangePromptModal,
        ExclamationMark,
        PageHeader,
        ConfirmCorrectiveMaintenanceCreationModal,
        Badge,

        CreateTestRecordModal,
        CreatePerformanceDataModal,
        CreateCorrectiveMaintenanceModal,
        CreateWorkOrderModal,
        TooltipWrapper,
        CalibrationRecordForm,
        JetButton,
        CheckAssetStatusForm,
        Wrench,
    },

    data() {
        return {
            showCreateWorkOrderConfirmationModal: false,
            forcedWorkOrderType: null,
            isOperationInProgress: false,
            updatingStatus: null,
            allTabs: [
                {
                    route: 'assets.show',
                    component: 'icon',
                    label: this.trans('assets.details.tab.details'),
                    permissions: ['asset.read'],
                },
                {
                    route: 'assets.tests.index',
                    component: 'icon',
                    label: this.trans('assets.details.tab.test_records'),
                    features: ['testRecord'],
                    permissions: ['test.index'],
                    badge: false,
                },
                {
                    route: 'assets.workorders.index',
                    component: 'wrench',
                    label: this.trans('assets.details.tab.workorders'),
                    permissions: ['workorder.index'],
                    features: ['assetWorkOrderManagement'],
                    badge: false,
                },
                {
                    route: 'assets.activities.index',
                    component: 'icon',
                    label: this.trans('assets.details.tab.activity_log'),
                    permissions: ['asset.activities.index'],
                },
                {
                    route: 'assets.performance.index',
                    component: 'icon',
                    label: 'Performance',
                    features: ['performance-data'],
                    permissions: ['performance_data.index'],
                },
            ],
        };
    },

    computed: {
        asset() {
            return this.$page.props.asset;
        },
        tabs() {
            return this.allTabs.filter((tab) => {
                let hasFeature = tab.features
                    ? this.hasFeature(tab.features)
                    : true;
                return this.hasAnyPermission(tab.permissions) && hasFeature;
            });
        },
        hasTestIssues() {
            return this.asset.tests.issues.length > 0;
        },
        hasMaintenanceIssues() {
            return this.asset.maintenances.issues.length > 0;
        },
        isAssetOnboarded() {
            return !!this.asset.petitec_identifier;
        },
    },

    methods: {
        isActiveRoute(route) {
            if (this.activeTab) {
                return this.activeTab === route;
            }

            const currentRoute = this.route().current();

            if (route.endsWith('.index') && currentRoute.endsWith('.show')) {
                return route === currentRoute.replace('.show', '.index');
            }

            return currentRoute === route;
        },
        openTab(route) {
            this.$inertia.visit(this.route(route, this.asset));
        },
        openCheckAssetStatus() {
            eventBus.$emit(events.openCreateStatusCheckModal);
        },
        openTestRecordModal() {
            eventBus.$emit(events.openTestRecordsModal);
        },
        openWorkOrderModal(type) {
            eventBus.$emit(events.openCreateWorkOrderModal, {
                assetId: this.asset.id,
            });
        },
        openPerformanceDataModal() {
            eventBus.$emit(events.openCreatePerformanceDataModal);
        },
        onTestRecordCreated(data) {
            this.promptUserForAssetStatusChange(data);
            this.updatedBadges();
        },
        updatedBadges() {
            // Test records badge
            this.allTabs[1].badge = this.hasTestIssues;

            // Maintenance Schedule badge
            this.allTabs[2].badge = this.hasMaintenanceIssues;
        },
        promptUserForAssetStatusChange(data) {
            if (
                data.resolution === 'fail' &&
                this.asset.status.operability === 'operable'
            ) {
                eventBus.$emit(events.openAssetStatusChangePrompt, data);
            }
        },
    },

    mounted() {
        this.updatedBadges();

        if (this.route().params['check-status'] === '1') {
            this.openCheckAssetStatus();
        }
    },

    beforeUnmount() {
        eventBus.$off(events.assetOperabilityUpdated);
    },
};
</script>
sty
