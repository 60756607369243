<template>
    <div>
        <data-table
            :actions="actions"
            :columns="fields"
            :items="performanceData"
            :no-items-view="noItemsView"
            :query-params="activeQueryParams"
        >
            <template #date="{ item }">
                <td class="align-middle">
                    <div class="text-muted">
                        <div class="text-nowrap">
                            {{
                                $filters.fullDate(item.createdAt, currentLocale)
                            }}
                        </div>
                        <small>
                            <icon icon="cil-clock" />
                            {{
                                $filters.time(item.createdAt, currentLocale)
                            }}</small
                        >
                    </div>
                </td>
            </template>

            <template #user="{ item }">
                <td class="align-middle">
                    <user-information v-if="item.user" :info="item.user" />
                    <span v-else>-</span>
                </td>
            </template>

            <template #type="{ item }">
                <td class="align-middle">
                    {{ item.type.label }}
                </td>
            </template>

            <template #value="{ item }">
                <td class="align-middle">
                    {{ item.value }}
                </td>
            </template>

            <template #attachments="{ item }">
                <td class="align-middle">
                    <file-list
                        :attachments="item.attachments"
                        :can-read="hasAnyPermission(['test.attachment.index'])"
                        :inline="true"
                        :readonly="true"
                    />
                </td>
            </template>
        </data-table>
        <jet-confirmation-modal
            :show="!!recordToDelete"
            type="danger"
            @close="recordToDelete = null"
        >
            <template #title> Delete Performance Data Record</template>

            <template #content>
                Do you really want to delete this record?
            </template>

            <template #footer>
                <slot name="footer">
                    <jet-button
                        :destructive="true"
                        :isProcessing="isDeleting"
                        type="button"
                        variant="secondary"
                        @click.stop="deletePerformanceData(recordToDelete)"
                    >
                        {{ trans('general.action.delete') }}
                    </jet-button>
                </slot>
            </template>
        </jet-confirmation-modal>
    </div>
</template>
<script>
import UserInformation from '@/Components/UserInformation.vue';
import JetButton from '@/Jetstream/Button.vue';
import FileList from '@/Components/FileList.vue';
import Pagination from '@/Components/Pagination.vue';
import { eventBus, events } from '@/eventBus.js';
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue';
import DataTable from '@/Components/DataTable.vue';
import useQueryParams from '@/Utils/UseQueryParams';
import { usePage } from '@inertiajs/vue3';

export default {
    name: 'PerformanceDataTable',
    components: {
        DataTable,
        JetConfirmationModal,
        Pagination,
        FileList,
        JetButton,
        UserInformation,
    },
    setup() {
        const page = usePage();
        const activeQueryParams = useQueryParams({
            paramsWithDefaults: {
                sort: { column: 'date', asc: false },
            },
            route: route('assets.performance.index', page.props.asset),
            options: {
                only: ['performanceData'],
            },
        });
        return {
            activeQueryParams,
        };
    },
    data() {
        return {
            recordToDelete: null,
            recordToEdit: null,
            isDeleting: false,
            fields: [
                {
                    key: 'date',
                    label: 'Date',
                    sorter: false,
                },
                {
                    key: 'user',
                    label: 'User',
                    sorter: false,
                },
                {
                    key: 'type',
                    label: 'Performance Data',
                    sorter: false,
                },
                {
                    key: 'value',
                    label: 'Value',
                    sorter: false,
                },
                {
                    key: 'attachments',
                    label: 'Attachments',
                    sorter: false,
                },
            ],
            actions: [
                {
                    permissions: ['performance_data.update'],
                    tooltip: this.trans('general.action.edit'),
                    type: 'primary',
                    click: this.editPerformanceData,
                    icon: 'cil-pencil',
                },
                {
                    permissions: ['performance_data.delete'],
                    tooltip: this.trans('general.action.delete'),
                    type: 'danger',
                    click: this.setItemToDelete,
                    icon: 'cil-trash',
                },
            ],
            noItemsView: {
                noItems: 'No data available',
            },
        };
    },
    computed: {
        performanceData() {
            return this.$page.props.performanceData;
        },
        asset() {
            return this.$page.props.asset;
        },
    },
    methods: {
        editPerformanceData(performanceData) {
            eventBus.$emit(events.editPerformanceDataItem, performanceData);
        },
        setItemToDelete(item) {
            this.recordToDelete = item;
        },
        deletePerformanceData(performanceData) {
            if (this.isDeleting) {
                return;
            }

            this.isDeleting = true;

            this.$inertia.delete(
                route('assets.performance.destroy', {
                    performance: performanceData.id,
                    asset: this.asset.id,
                }),
                {
                    onFinish: () => {
                        this.isDeleting = false;
                        this.recordToDelete = null;
                    },
                },
            );
        },
    },
};
</script>
