<template>
    <app-layout title="Components">
        <table cellpadding="10">
            <thead>
                <tr>
                    <td></td>
                    <td>Primary</td>
                    <td>Secondary</td>
                    <td>Tertiary</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Active</td>
                    <td><button class="btn btn-primary">Get a Demo</button></td>
                    <td>
                        <button class="btn btn-secondary">Get a Demo</button>
                    </td>
                    <td>
                        <button class="btn btn-tertiary">Get a Demo</button>
                    </td>
                </tr>
                <tr>
                    <td>Disruptive</td>
                    <td></td>
                    <td>
                        <button class="btn btn-secondary disruptive">
                            Get a Demo
                        </button>
                    </td>
                    <td>
                        <button class="btn btn-tertiary disruptive">
                            Get a Demo
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>Disabled</td>
                    <td>
                        <button class="btn btn-primary disabled">
                            Get a Demo
                        </button>
                    </td>
                    <td>
                        <button class="btn btn-secondary disabled">
                            Get a Demo
                        </button>
                    </td>
                    <td>
                        <button class="btn btn-tertiary disabled">
                            Get a Demo
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>Pressed</td>
                    <td>
                        <button class="btn btn-primary active">
                            Get a Demo
                        </button>
                    </td>
                    <td>
                        <button class="btn btn-secondary active">
                            Get a Demo
                        </button>
                    </td>
                    <td>
                        <button class="btn btn-tertiary active">
                            Get a Demo
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </app-layout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue';

export default {
    components: { AppLayout },
};
</script>
<style scoped></style>
