<template>
    <div>
        <dl>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ trans('workorder.form.asset.asset.label') }}
                </dt>
                <dd :class="{ deleted: isDeleted }" class="col-9 text-muted">
                    <span v-if="isDeleted">{{ asset?.name }}</span>
                    <Link v-else :href="url">{{ asset?.name }}</Link>
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ trans('workorder.form.asset.type.label') }}
                </dt>
                <dd :class="{ deleted: isDeleted }" class="col-9 text-muted">
                    <span>{{ type }}</span>
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ trans('workorder.form.asset.status.label') }}
                </dt>
                <dd v-if="isDeleted" class="col-9 text-muted">
                    <span
                        class="text-danger d-flex align-items-center"
                        style="gap: 4px"
                    >
                        <exclamation-mark />
                        {{ trans('workorder.form.asset.status.deleted') }}</span
                    >
                </dd>
                <dd v-else class="col-9 text-muted">
                    <div v-if="status">
                        <status-badge
                            :inline="true"
                            :operability="status.operability"
                            :usage="status.usageCategory"
                            class="text-muted"
                        />
                        <additional-status-information
                            :additional-information="
                                asset.status
                                    .operabilityChangeAdditionalInformation
                            "
                            :asset="asset"
                            :is-onboarded="!!asset.petitec_identifier"
                            :is-operable="
                                asset.status.operability === 'operable'
                            "
                            :reason="asset.status.operabilityChangeReason"
                        />
                    </div>
                    <div v-else>{{ trans('general.not_available') }}</div>
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ trans('workorder.form.asset.usage_category.label') }}
                </dt>
                <dd :class="{ deleted: isDeleted }" class="col-9 text-muted">
                    {{ usageCategory }}
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ trans('workorder.form.asset.petitec_id.label') }}
                </dt>
                <dd :class="{ deleted: isDeleted }" class="col-9 text-muted">
                    {{ petitecId }}
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ trans('workorder.form.asset.serial_number.label') }}
                </dt>
                <dd :class="{ deleted: isDeleted }" class="col-9 text-muted">
                    {{ serialNumber }}
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ trans('assets.details.description.label') }}:
                </dt>
                <dd class="col-9 text-muted text-pre-line">
                    {{ description }}
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ trans('assets.details.location.label') }}:
                </dt>
                <dd :class="{ deleted: isDeleted }" class="col-9 text-muted">
                    <span>{{ location }}</span>
                </dd>
            </div>
            <div v-if="specificLocation" class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ trans('assets.details.specific_location.label') }}:
                </dt>
                <dd :class="{ deleted: isDeleted }" class="col-9 text-muted">
                    <span>{{ specificLocation }}</span>
                </dd>
            </div>
            <div v-if="areaLocation" class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ trans('assets.details.area_location.label') }}:
                </dt>
                <dd :class="{ deleted: isDeleted }" class="col-9 text-muted">
                    <span>{{ areaLocation }}</span>
                </dd>
            </div>
            <div v-if="subAreaLocation" class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ trans('assets.details.sub_area_location.label') }}:
                </dt>
                <dd :class="{ deleted: isDeleted }" class="col-9 text-muted">
                    <span>{{ subAreaLocation }}</span>
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ trans('workorder.form.asset.manufacturer.label') }}
                </dt>
                <dd :class="{ deleted: isDeleted }" class="col-9 text-muted">
                    <span>{{ manufacturer }}</span>
                </dd>
            </div>
        </dl>
    </div>
</template>
<script>
import StatusBadge from '@/Components/StatusBadge.vue';
import ExclamationMark from '@/../icons/ExclamationMark.vue';
import AdditionalStatusInformation from '@/Components/AdditionalStatusInformation.vue';
import { Link } from '@inertiajs/vue3';

export default {
    name: 'work-order-form-asset-details',
    components: {
        AdditionalStatusInformation,
        ExclamationMark,
        StatusBadge,
        Link,
    },
    props: ['asset'],
    computed: {
        url() {
            if (!this.asset) {
                return;
            }
            return this.route('assets.show', this.asset);
        },
        type() {
            return (
                this.asset?.type?.label || this.trans('general.not_available')
            );
        },
        status() {
            return this.asset?.status;
        },
        petitecId() {
            return (
                this.asset?.petitec_identifier ||
                this.trans('general.not_available')
            );
        },
        usageCategory() {
            return this.asset?.status.usageCategory
                ? this.trans('assets.status.' + this.asset.status.usageCategory)
                : this.trans('general.not_available');
        },
        serialNumber() {
            return (
                this.asset?.serial_number || this.trans('general.not_available')
            );
        },
        description() {
            return (
                this.asset?.description || this.trans('general.not_available')
            );
        },
        location() {
            return (
                this.asset?.location.hierarchy[0]?.name ||
                this.trans('general.not_available')
            );
        },
        specificLocation() {
            return (
                this.asset?.location.hierarchy[1]?.name ||
                this.trans('general.not_available')
            );
        },
        areaLocation() {
            return this.asset?.location.hierarchy[2]?.name;
        },
        subAreaLocation() {
            return this.asset?.location.hierarchy[3]?.name;
        },
        manufacturer() {
            return (
                this.asset?.manufacturer?.name ||
                this.trans('general.not_available')
            );
        },
        isDeleted() {
            return !!this.asset?.deleted_at;
        },
    },
};
</script>
<style scoped>
.deleted {
    text-decoration: line-through;
}
</style>
