<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-2">
            <h3 class="mb-0">{{ trans('owners.overview.title') }}</h3>
            <div class="d-flex align-items-end center gap-3">
                <small class="text-muted">{{
                    trans('owners.overview.results.count', {
                        total: owners.data.length,
                    })
                }}</small>
                <jet-button
                    v-if="!isTablet && hasAnyPermission('owner.create')"
                    type="button"
                    variant="secondary"
                    @click.stop="createOwner"
                    >{{ trans('owners.overview.action.add') }}
                </jet-button>
            </div>
        </div>

        <div v-if="owners.data.length === 0">
            <card style="padding: 0 !important" type="decent">
                <div class="text-center text-gray">
                    <div class="text-semi-bold">
                        {{ trans('stakeholders.overview.empty.owners.title') }}
                    </div>
                    <div>
                        {{ trans('stakeholders.overview.empty.description') }}
                    </div>
                </div>
            </card>
        </div>
        <div v-else>
            <card
                v-for="owner in owners.data"
                :key="owner.id"
                card-classes="mb-2"
                style="padding: 0 !important"
                type="decent"
            >
                <header
                    class="d-flex justify-content-between align-items-center"
                >
                    <h4 class="h6">{{ owner.name }}</h4>
                    <div
                        v-if="
                            !isTablet &&
                            hasAnyPermission(['owner.update', 'owner.delete'])
                        "
                        class="d-flex gap-1"
                    >
                        <jet-button
                            v-if="hasAnyPermission('owner.update')"
                            v-c-tooltip="{
                                content: trans('general.action.edit'),
                            }"
                            class="btn-icon"
                            type="button"
                            variant="secondary"
                            @click="editOwner(owner)"
                        >
                            <icon name="cil-pencil" />
                        </jet-button>
                        <jet-button
                            v-if="hasAnyPermission('owner.delete')"
                            v-c-tooltip="{
                                content: trans('general.action.delete'),
                            }"
                            :destructive="true"
                            class="btn-icon"
                            type="button"
                            variant="secondary"
                            @click="deleteOwner(owner)"
                        >
                            <icon name="cil-trash" />
                        </jet-button>
                    </div>
                </header>
                <section>
                    <row>
                        <column lg="2" md="3">
                            <div class="text-nowrap">
                                {{
                                    trans(
                                        'stakeholders.overview.assets_count.label',
                                        {
                                            count: owner.assetsCount || 0,
                                        },
                                    )
                                }}
                            </div>
                            <div class="text-nowrap">
                                {{
                                    trans(
                                        'stakeholders.overview.locations_count.label',
                                        {
                                            count:
                                                owner.topLevelLocationsCount ||
                                                0,
                                        },
                                    )
                                }}
                            </div>
                        </column>
                        <column>
                            <div v-if="owner.email || owner.description">
                                <div v-if="owner.email">
                                    <email-link
                                        :email="owner.email"
                                        :show-icon="true"
                                    />
                                </div>
                                <div
                                    v-if="owner.description"
                                    class="d-flex align-items-top gap-1"
                                >
                                    <icon
                                        name="cil-briefcase"
                                        style="position: relative; top: 1px"
                                    />
                                    <span>{{ owner.description }}</span>
                                </div>
                            </div>
                            <div v-else>
                                {{ trans('general.not_available') }}
                            </div>
                        </column>
                    </row>
                </section>
            </card>
        </div>

        <update-or-create-owner-form-modal />
    </div>
</template>
<script>
import JetButton from '@/Jetstream/Button.vue';
import Pagination from '@/Components/Pagination.vue';
import TotalTableItems from '@/Components/TotalTableItems.vue';
import EmailLink from '@/Components/EmailLink.vue';
import UpdateOrCreateOwnerFormModal from '@/Pages/Stakeholders/Owners/UpdateOrCreateOwnerFormModal.vue';
import { eventBus, events } from '@/eventBus.js';
import DeleteStakeholderModal from '@/Pages/Stakeholders/DeleteStakeholderModal.vue';
import Card from '@/Components/Card.vue';
import { trans } from '@/mixins/i18n.js';
import { isTablet } from '@/Utils/UseMedia';
import { hasAnyPermission } from '@/mixins/hasAnyPermission.js';

export default {
    name: 'OwnersOverview',
    components: {
        Card,
        DeleteStakeholderModal,
        UpdateOrCreateOwnerFormModal,
        EmailLink,
        TotalTableItems,
        Pagination,
        JetButton,
    },
    setup() {
        return { isTablet };
    },
    computed: {
        owners() {
            return this.$page.props.owners;
        },
    },
    methods: {
        hasAnyPermission,
        trans,
        createOwner() {
            eventBus.$emit(events.openCreateOwnerModal);
        },
        editOwner(item) {
            eventBus.$emit(events.openEditOwnerModal, { owner: item });
        },
        deleteOwner(item) {
            eventBus.$emit(events.openConfirmDeleteStakeholderModal, {
                stakeholder: item,
                type: 'owner',
            });
        },
    },
};
</script>
