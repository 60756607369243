<template>
    <div :class="{ 'bg-brand': isLoginRoute }" class="min-vh-100">
        <c-header
            v-if="isTablet"
            class="justify-content-end position-fixed"
            style="left: 0; right: 0; z-index: 999"
            with-subheader
        >
            <c-header-nav class="justify-content-between w-100">
                <c-nav-item class="d-flex align-items-center">
                    <a class="text-white" href="/">
                        <LogoCurie :height="28" :width="105" />
                    </a>
                </c-nav-item>
                <language-selector />
            </c-header-nav>
        </c-header>
        <slot />
    </div>
</template>
<script setup>
import LanguageSelector from '@/Containers/LanguageSelector.vue';
import LogoCurie from '../../icons/LogoCurie.vue';
import { computed } from 'vue';
import { isTablet } from '@/Utils/UseMedia';
import { route } from 'ziggy-js';

const isLoginRoute = computed(() => {
    return route().current('login');
});
</script>
